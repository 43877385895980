import React from "react";
import core from "../../vendors/core-api";
import ProSourceForm from "../../components/ProSourceForm";
import {
  Block,
  FormItem,
  InfoAlertHelper,
  InfoModal,
  InfoTooltip,
  Label,
} from "../../components/Base";
import { Button, Col, Form, Row } from "react-bootstrap";
import {
  checkIfCanUpdateField,
  disableCheckbox,
  GetDataFromEvent,
  GetSubmitClassList,
  Humanize,
} from "../../helpers";
import {
  CONSOLE_USERS,
  PAGES_PRIVILEGES,
  PRIMARY_COLOR,
  STORE_USERS,
} from "../../constants";
import DisplayBrandsDropdown from "./DisplayBrandsDropdown";
import DisplayStoresDropdown from "./DisplayStoresDropdown";
import DisplayRolesDropdown from "./DisplayRolesDropdown";
import DisplayPrivilegesText from "./DisplayPrivilegesText";
import DisplayStoresText from "./DisplayStoresText";
import { useSelector } from "react-redux";
import DisplayAssignedTitle from "./DisplayAssignedTitle";
import DisplayBrandsText from "./DisplayBrandsText";
import { getBrands } from "./helpers";
import ht from "../../helpertexts";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

function verifyAssignment(user = {}) {
  const { iam_role = "", brands = [], stores = [] } = user;
  if (iam_role === "brand_admin") {
    return brands.length > 0;
  } else if (STORE_USERS.includes(iam_role)) {
    return stores.length > 0;
  } else {
    return true;
  }
}

export default function UserPrivilegeForm(props) {
  const { user } = useSelector((state) => state.auth);
  return <UserPrivilegeFormClass {...props} currentUser={user} />;
}

class UserPrivilegeFormClass extends ProSourceForm {
  constructor(props) {
    super(props);

    const { user = {} } = this.props,
      {
        first_name = "",
        last_name = "",
        contact_number = "",
        email = "",
        iam_role = "",
        is_accounting = false,
        active = true,
        privileges = [],
        brands = [],
        stores = [],
      } = user;

    this.state = {
      values: {
        first_name,
        last_name,
        contact_number,
        email,
        iam_role,
        is_accounting,
        active,
        privileges,
        brands,
        stores,
      },
      all_users: [],
      errors: {},
      touched: {},
      isSubmitting: false,
      checkingEmail: false,
      mountDropdown: true,
    };

    this.validate = {
      required: ["first_name", "last_name", "email", "iam_role"],
    };

    this.api = core("pu").get();
    this.data_url = "/users";

    this.handleOnClickDeleteBtn = this.handleOnClickDeleteBtn.bind(this);
    this.handleOnSelectBrands = this.handleOnSelectBrands.bind(this);
    this.handleOnSelectStores = this.handleOnSelectStores.bind(this);
    this.onChangePrivilege = this.onChangePrivilege.bind(this);
    this.handleOnBlurBrands = this.handleOnBlurBrands.bind(this);
    this.handleOnBlurStores = this.handleOnBlurStores.bind(this);
  }

  async componentDidMount() {
    await this.api.get(this.data_url).then((result) => {
      this.setState({ all_users: result.data });
    });
  }

  onChangePrivilege = (event) => {
    const target = event.target,
      checked = target.checked,
      value = GetDataFromEvent(event, "data-value");
    let { values = {} } = this.state;

    if (checked) {
      values.privileges.push(value);
    } else {
      values.privileges = values.privileges.filter((privilege) => {
        return privilege !== value;
      });
    }

    this.setState({ values });
  };

  onChange = (event) => {
    const target = event.target,
      name = target.name,
      value = target.value;

    let { values = {} } = this.state,
      state = {};

    if (name === "iam_role") {
      if (
        value === "group_admin" ||
        value === "brand_admin" ||
        value === "store_admin" ||
        value === "accounting"
      ) {
        values["privileges"] = PAGES_PRIVILEGES;
      } else if (CONSOLE_USERS.includes(value)) {
        values["privileges"] = ["home", "menu", "orders"];
        if (values.hasOwnProperty("stores") && values.stores.length > 0) {
          values.stores = [values.stores[0]];
        }
      } else {
        values["privileges"] = [];
      }

      state.values = values;
      state.mountDropdown = false;

      this.removeErrors(["stores", "brands"]);
    }

    this.setState(state);

    setTimeout(() => {
      this.setState({ mountDropdown: true });
    }, 50);
  };

  onBlur = (event) => {
    const target = event.target,
      name = target.name,
      value = target.value;
    let { errors = {} } = this.state;

    if (name === "email" && value !== "") {
      this.setState({ checkingEmail: true });
      this.props.api
        .post({
          url: "/external/users/validate",
          data: { email: value },
        })
        .then(({ data }) => {
          this.setState({ checkingEmail: false });
          const { existing = false } = data;
          if (existing) {
            errors["email"] = "Email is already taken.";
          } else {
            delete errors["email"];
          }

          this.setState({ errors });
        })
        .catch(() => {
          this.props.handleError();
        })
        .finally(() => {
          this.setState({ checkingEmail: false });
        });
    }
  };

  onSubmit = async (values, setSubmitting) => {
    const { all_users } = this.state;
    const roleArr = all_users.map((user) => {
      return {
        email: user.email,
        iam_role: user.iam_role,
        active: user.active,
      };
    });
    if (verifyAssignment(values)) {
      // check if at least one group_admin
      const groupAdminArr = roleArr.filter((user) => {
        return Object.keys(user).some((k) => {
          return (
            k === "iam_role" &&
            user[k] === "group_admin" &&
            user["active"] === true
          );
        });
      });
      let inGroupAdminArr = false;
      for (let j = 0; j < groupAdminArr.length; j++) {
        if (groupAdminArr[j].email === values.email) {
          inGroupAdminArr = true;
          break;
        }
      }
      if (
        inGroupAdminArr &&
        values.iam_role !== "group_admin" &&
        groupAdminArr.length <= 1
      ) {
        // console.log("No more group admin");
        await MySwal.fire({
          icon: "error",
          title: "No More Group Admins Available",
          text: "To change the admin's role, more group admins are required. Add another group admin or assign the desired role to a new user.",
          confirmButtonColor: PRIMARY_COLOR,
          confirmButtonText: "Ok",
          // timer: 1000
        });
        this.setSubmitting(false);
      } else {
        this.props.onSubmit(values, setSubmitting);
      }
    } else {
      setSubmitting(false);
    }
  };

  handleOnClickDeleteBtn = () => {
    this.props.onClickDeleteBtn && this.props.onClickDeleteBtn();
  };

  handleOnSelectBrands = (brands) => {
    let { values = {} } = this.state;
    values.brands = brands.map(({ _id = "" }) => {
      return _id;
    });
    values.stores = [];
    this.setState({ values });
  };

  handleOnSelectStores = (stores) => {
    let { values = {} } = this.state;
    values.stores = stores.map(({ _id = "" }) => {
      return _id;
    });

    if (CONSOLE_USERS.includes(values.iam_role)) {
      // Only single store can be assigned to this roles
      values.stores = [values.stores[0]];
    }

    values.brands = getBrands(stores);

    if (values.brands.length > 1) {
      this.addError("stores", "You can only add stores from same brand");
    } else {
      this.removeError("stores");
    }

    this.setState({ values });
  };

  handleOnBlurBrands = (selectedBrands) => {
    if (selectedBrands.length === 0) {
      this.addError("brands", "This field is required");
    } else {
      this.removeError("brands");
    }
  };

  handleOnBlurStores = (selectedStores) => {
    const brands = getBrands(selectedStores);

    if (selectedStores.length === 0) {
      this.addError("stores", "This field is required");
    } else if (brands.length > 1) {
      this.addError("stores", "You can only add stores from same brand");
    } else {
      this.removeError("stores");
    }
  };

  render() {
    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      },
      { submitButton = "Save Changes", showBackButton = false } = this.props,
      { checkingEmail = false, values = {}, mountDropdown = true } = this.state,
      { privileges = [] } = values;
    if (!privileges.includes("help")) {
      privileges.push("help");
    }

    const isClientAdmin = this.props.hasOwnProperty("user")
        ? this.props.user.hasOwnProperty("iam_role") &&
          this.props.user.iam_role === "group_admin"
        : false,
      isActive = values.active,
      canUpdate = checkIfCanUpdateField(
        this.props.user,
        this.props.currentUser,
      );

    return canUpdate ? (
      <Form onSubmit={this.handleOnSubmit}>
        <InfoAlertHelper
          text={"You can manage the user's roles and privileges here."}
        />

        <Row>
          <Col>
            <FormItem
              label={<>Role:{InfoTooltip(ht.users.roles)}</>}
              name={"iam_role"}
              actions={actions}
              showRequired={true}
              normal
              customFormControl
              custom={
                <>
                  <Form.Control
                    as="select"
                    value={this.state.values["iam_role"]}
                    {...inputActions}
                    disabled={!isActive ? "disabled" : ""}
                    name="iam_role"
                    className="text-capitalize"
                  >
                    <DisplayRolesDropdown
                      value={this.state.values["iam_role"]}
                    />
                  </Form.Control>
                </>
              }
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <DisplayBrandsDropdown
              iam_role={this.state.values["iam_role"]}
              action={this.handleOnSelectBrands}
              user={this.props.user}
              actions={actions}
              onBlur={this.handleOnBlurBrands}
            />
            {mountDropdown ? (
              <DisplayStoresDropdown
                iam_role={this.state.values["iam_role"]}
                action={this.handleOnSelectStores}
                user={this.props.user}
                actions={actions} // for FormItem
                onBlur={this.handleOnBlurStores}
              />
            ) : (
              <>Loading...</>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Label
                text={<>Privileges:{InfoTooltip(ht.users.privileges)}</>}
              />
              <div className="checkbox-list">
                {PAGES_PRIVILEGES.map((page = "", index) => {
                  return (
                    <label
                      key={index}
                      className={`checkbox text-capitalize${
                        disableCheckbox(this.state.values["iam_role"], page)
                          ? " checkbox-disabled"
                          : ""
                      }`}
                    >
                      <input
                        type="checkbox"
                        data-value={page}
                        name={`page-${page}`}
                        onChange={this.onChangePrivilege}
                        checked={
                          privileges.indexOf(page) !== -1 ? "checked" : ""
                        }
                        disabled={
                          !isActive ||
                          disableCheckbox(this.state.values["iam_role"], page)
                            ? "disabled"
                            : ""
                        }
                      />{" "}
                      {Humanize(page)}
                      <span></span>
                    </label>
                  );
                })}
              </div>
            </Form.Group>
          </Col>
        </Row>

        <div className="d-flex flex-row justify-content-space-between">
          <div>
            {showBackButton ? (
              <Button
                type="button"
                variant="secondary"
                onClick={this.props.onClickBackBtn}
                className={GetSubmitClassList(
                  this.state.isSubmitting,
                  `btn btn-primary font-weight-bold px-9 py-4 my-3`,
                )}
              >
                <span>Back</span>
              </Button>
            ) : (
              <></>
            )}
          </div>
          <div className="text-right">
            {this.props.showDeleteButton && !isClientAdmin ? (
              <button
                type="button"
                disabled={this.props.deleteLoading}
                className={GetSubmitClassList(
                  this.props.deleteLoading,
                  "btn btn-danger font-weight-bold px-9 py-4 my-3 mr-2",
                )}
                onClick={this.props.handleOnClickDeleteBtn}
              >
                <span>Mark as {isActive ? <>inactive</> : <>active</>}</span>
              </button>
            ) : (
              <></>
            )}
            {isActive ? (
              <button
                type="submit"
                disabled={this.state.isSubmitting || checkingEmail}
                className={GetSubmitClassList(
                  this.state.isSubmitting,
                  `btn btn-primary font-weight-bold px-9 py-4 my-3`,
                )}
              >
                <span>{submitButton}</span>
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Form>
    ) : (
      <>
        <Row>
          <Col>
            <Block
              content={
                <>
                  <Label text="Role" />
                  <div className="text-capitalize">
                    {Humanize(this.state.values.iam_role)}
                  </div>
                </>
              }
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Block
              content={
                <>
                  <Label
                    text={<DisplayAssignedTitle user={this.state.values} />}
                  />
                  <div>
                    <DisplayBrandsText user={this.state.values} />
                    <DisplayStoresText user={this.state.values} />
                  </div>
                </>
              }
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Block
              content={
                <>
                  <Label text="Privileges" />
                  <div>
                    <DisplayPrivilegesText
                      privileges={this.state.values.privileges}
                    />
                  </div>
                </>
              }
            />
          </Col>
        </Row>
      </>
    );
  }
}
