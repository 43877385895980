import React from "react";
import { Button } from "react-bootstrap";
// import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PRIMARY_COLOR } from "../../constants";
import StoreCardFooter from "../../partials/stores/StoreCardFooter";
import { SVGIcon } from "../Base";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

export default class StoreCard extends React.Component {
  state = {
    copyButtonVariant: "primary",
  };

  countItemsSold = (orders) => {
    let items_sold = 0;
    orders.forEach(({ order_details }) => {
      items_sold = order_details.reduce(
        (acc, curr) => acc + curr.qty,
        items_sold,
      );
    });
    return new Intl.NumberFormat().format(items_sold);
  };

  countTotalEarnings = (orders) => {
    let total_earnings = 0;
    orders.forEach(({ order_details }) => {
      total_earnings = order_details.reduce(
        (acc, curr) => acc + curr.qty * curr.price,
        total_earnings,
      );
    });
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "PHP",
    })
      .format(total_earnings)
      .replace("PHP", "")
      .trim();
  };

  showUnpublishedError = (isPublished, status) => {
    if (!isPublished) {
      if (status === "public") {
        MySwal.fire({
          icon: "info",
          title: "No Store URL",
          text: "Please go to the Store Settings to assign a URL.",
          confirmButtonColor: PRIMARY_COLOR,
          confirmButtonText: "OK",
          showCancelButton: false,
        });
      } else {
        MySwal.fire({
          icon: "info",
          title: "This store is not published yet.",
          text: "Please go to Store Settings then Website Status to publish this store.",
          confirmButtonColor: PRIMARY_COLOR,
          confirmButtonText: "OK",
          showCancelButton: false,
        });
      }
    }
  };

  render() {
    const {
      name = "",
      showFooter = true,
      orders = [],
      menu_link = "",
      settings_link = "",
      createCopy,
      copyLoading,
      createCopyAllowed,
      url,
      website_status,
      brand_url,
    } = this.props;

    const published = Boolean(url && website_status === "public");

    return (
      <>
        <div className="card card-custom gutter-b">
          <div className="card-body">
            <div className="d-flex">
              <div className="flex-grow-1">
                {createCopyAllowed && (
                  <Button
                    className={`float-right ${
                      copyLoading ? "spinner spinner-right" : ""
                    }`}
                    variant="outline-primary"
                    size="sm"
                    onMouseEnter={() => {
                      this.setState({ copyButtonVariant: "light" });
                    }}
                    onMouseLeave={() => {
                      this.setState({ copyButtonVariant: "primary" });
                    }}
                    onClick={async () => {
                      await MySwal.fire({
                        icon: "warning",
                        title: "Are you sure?",
                        html: `You are about to create a copy of <br/> <b>${name}</b>`,
                        confirmButtonColor: PRIMARY_COLOR,
                        confirmButtonText: "Yes",
                        showCancelButton: true,
                        cancelButtonText: "No",
                      }).then(async (result) => {
                        if (result.value) {
                          createCopy();
                          this.setState({ copyButtonVariant: "primary" });
                        }
                      });
                    }}
                    disabled={copyLoading}
                  >
                    <SVGIcon
                      icon="General/Duplicate"
                      title={"Duplicate store"}
                      className="mr-1"
                      variant={this.state.copyButtonVariant}
                    />
                    Create a copy
                  </Button>
                )}
                <div className="d-flex align-items-center justify-content-between flex-wrap mt-2">
                  <div className="mr-3 w-100">
                    <a
                      href={
                        published
                          ? `https://${brand_url}${process.env.REACT_APP_DOMAIN}/${url}`
                          : "#!"
                      }
                      target={published ? "_blank" : "_self"}
                      className={`d-flex align-items-center font-size-h5 font-weight-bold mr-3 clickable ${
                        published ? "text-dark" : "text-danger"
                      }`}
                      onClick={() =>
                        this.showUnpublishedError(published, website_status)
                      }
                      rel="noreferrer"
                    >
                      {name}
                    </a>
                    <div className="d-flex flex-wrap my-2 w-100">
                      <Link to={settings_link}>
                        <span className="font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2 text-uppercase text-primary text-nowrap">
                          <SVGIcon
                            icon="Code/Settings4"
                            title={"Store Settings"}
                            className="svg-icon-md svg-icon-gray-500 mr-1"
                          />
                          Store Settings
                        </span>
                      </Link>
                      <Link to={menu_link}>
                        <span className="font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2 text-uppercase text-primary text-nowrap">
                          <SVGIcon
                            icon="Layout/Layout-4-blocks"
                            title={"View Menu"}
                            className="svg-icon-md svg-icon-gray-500 mr-1"
                          />
                          View Menu
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {showFooter ? (
              <>
                <div className="separator separator-solid my-7"></div>
                <StoreCardFooter store_id={this.props.store_id} />
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </>
    );
  }
}
