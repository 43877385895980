import React from "react";
import ProSourceForm from "../../components/ProSourceForm";
import {
  Form,
  Row,
  Col,
  Accordion,
  Card,
  Button,
  ListGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { BrandLogo } from "../BrandSetup";
import ImageUpload from "../../components/ImageUpload";
import {
  FormItem,
  RequiredAsterisk,
  Label,
  InfoAlertHelper,
  InfoTooltip,
} from "../../components/Base";
import {
  getDefaultTimeSlots,
  GetSubmitClassList,
  getBatchValueOfTimeSlotLimits,
} from "../../helpers";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { DB_DATE_FORMAT, ITEM_DESCRIPTION_CHAR_LIMIT } from "../../constants";
import { Checkbox } from "@material-ui/core";
import ItemTags from "./ItemTags";
import ApplyToAllField from "../../components/widgets/ApplyToAllField";
import moment from "moment";
import { isEmpty } from "lodash";
// import ItemListDnD from "../../partials/ItemListDnD";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

export default class ItemForm extends ProSourceForm {
  constructor(props) {
    super(props);
    const { item = {}, noCategory = false } = this.props,
      {
        category = "",
        item_id = {},
        available = true,
        visible = false,
        hotItem = false,
        item_limit_per_day = 0,
        item_limit_per_time_slot = [],
      } = item,
      {
        name = "",
        description = "",
        price = "",
        image_url = "",
        _id = "",
        extragroup = [],
        regular_price = "",
        discount_type = "",
        discount_value = "",
        active_discount = "",
        discounted_price = "",
        discount_description = "",
        isMealPlan = false,
        isBulk = false,
        off_dates = [],
        isVisibleOnOffDate = false,
        isDineInExclusive = false,
        exclusives = [],
      } = item_id;

    const today_date = moment(new Date()).format(DB_DATE_FORMAT);
    this.state = {
      values: {
        name,
        description,
        price,
        discount_toggle: Number(active_discount) === 0 ? false : true,
        regular_price,
        discount_type,
        discount_value,
        active_discount,
        discounted_price,
        discount_description,
        isMealPlan,
        isBulk,
        category,
        image_url,
        _id,
        available,
        visible,
        hotItem,
        item_limit_per_day: item_limit_per_day == null ? 0 : item_limit_per_day,
        extragroup: extragroup.filter((item) => !isEmpty(item._id)),
        item_limit_per_time_slot,
        item_limit_per_time_slot_batch: getBatchValueOfTimeSlotLimits(
          item_limit_per_time_slot,
        ),
        tags:
          typeof this.props.item !== "undefined"
            ? this.props.item.item_id.tags
            : [],
        tag_settings: this.props.store.tag_settings,
        apply_to_all: false,
        isUnavailableToday: off_dates.includes(today_date),
        isVisibleOnOffDate,
        off_dates,
        userId: this.props.userId,
        role: this.props.role,
        image_updated: false,
        isDineInExclusive,
        exclusives,
      },
      today_date,
      changedValues: [],
      errors: {},
      touched: {},
      isSubmitting: false,
      file: null,
      itemDescriptionCharCount: description.length,
      discountDescriptionCharCount: discount_description.length,
      showVisibilityToggle: !available,
      isLimitEnabled: false,
    };

    this.validate = {
      required: noCategory
        ? ["name", "regular_price", "image_url"]
        : ["name", "regular_price", "category", "image_url"],
    };

    this.afterSubmit = this.afterSubmit.bind(this);
    this.handleOnClickCancelBtn = this.handleOnClickCancelBtn.bind(this);
    this.handleOnBlurCategory = this.handleOnBlurCategory.bind(this);
    this.handleOnClickDeleteBtn = this.handleOnClickDeleteBtn.bind(this);
  }

  handleOnClickCancelBtn = () => {
    this.props.onClickCancelBtn && this.props.onClickCancelBtn();
  };

  handleOnClickDeleteBtn = () => {
    this.props.onClickDeleteBtn &&
      this.props.onClickDeleteBtn(this.state.values.apply_to_all);
  };

  handleOnBlurCategory = () => {
    this.setStateKey("touched", "category", "true");
    this.validateForm();
  };

  handleOnChange = (event) => {
    const target = event.target,
      value = target.value,
      name = target.name;

    let { values } = this.state;

    if (values[name] !== value) {
      this.updateChanges(name, value, event);
    }

    if (
      name === "description" &&
      value !== "" &&
      value.length > ITEM_DESCRIPTION_CHAR_LIMIT
    ) {
      // do nothing
    } else {
      values[name] = value;

      this.setState({
        values: values,
      });
    }

    if (
      name === "discount_description" &&
      value !== "" &&
      value.length > ITEM_DESCRIPTION_CHAR_LIMIT
    ) {
      // do nothing
    } else {
      values[name] = value;

      this.setState({
        values: values,
      });
    }

    this.setStateKey("touched", name, "true");
    this.validateForm();

    // checks if afterOnChange function exists
    this.onChange && this.onChange(event);
  };

  validateLimitInput = (input) => {
    const newInput = String(input).replace(/[^0-9]/g, "");
    if (newInput.length > 7) {
      return newInput.slice(0, 6);
    } else {
      return newInput;
    }
  };

  onChange = (event) => {
    const target = event.target,
      checked = target.checked,
      name = target.name,
      value = target.value;
    let { values = {} } = this.state,
      state = {};
    let { errors = {} } = this.state;
    if (name === "available") {
      values[name] = checked;
      state.values = values;
      this.setState({ showVisibilityToggle: !checked });
    } else if (name === "visible") {
      values[name] = checked;
      state.values = values;
    } else if (name === "hotItem") {
      values[name] = checked;
      state.values = values;
    } else if (name === "description") {
      state.itemDescriptionCharCount = values.hasOwnProperty("description")
        ? values.description.length
        : 0;
      if (state.itemDescriptionCharCount > ITEM_DESCRIPTION_CHAR_LIMIT) {
        errors["description"] = "Description character limit exceeded.";
      } else {
        delete errors["description"];
      }
      this.setState({ errors });
    } else if (name === "discount_description") {
      state.discountDescriptionCharCount = values.hasOwnProperty(
        "discount_description",
      )
        ? values.discount_description.length
        : 0;
      if (state.discountDescriptionCharCount > ITEM_DESCRIPTION_CHAR_LIMIT) {
        errors["discount_description"] =
          "Description character limit exceeded.";
      } else {
        delete errors["discount_description"];
      }
      this.setState({ errors });
    } else if (name === "item_limit_per_time_slot_batch") {
      let {
        item_limit_per_time_slot = [],
        item_limit_per_time_slot_batch,
        item_limit_per_day,
      } = values;
      if (!isEmpty(item_limit_per_time_slot_batch)) {
        if (
          Number(item_limit_per_day) !== 0 &&
          Number(item_limit_per_day) < Number(item_limit_per_time_slot_batch)
        ) {
          errors["item_limit_per_time_slot_batch"] =
            "Value is more than daily limit";
          state.errors = errors;
        } else {
          delete errors["item_limit_per_time_slot_batch"];

          if (item_limit_per_time_slot.length === 0) {
            item_limit_per_time_slot = getDefaultTimeSlots(this.props.store);
          }
          const validateInput = this.validateLimitInput(value);

          values.item_limit_per_time_slot = item_limit_per_time_slot.map(
            (i) => {
              i.limit = parseInt(validateInput);
              return i;
            },
          );

          values.item_limit_per_time_slot_batch = parseInt(validateInput);

          state.values = values;
        }
      } else {
        errors["item_limit_per_time_slot_batch"] = "Invalid input";
        state.errors = errors;
      }
    } else if (name === "item_limit_per_day") {
      let { item_limit_per_day, item_limit_per_time_slot_batch, category } =
        values;
      if (!isEmpty(item_limit_per_day)) {
        const { store } = this.props;
        const { categories } = store;
        const selectedCategory = categories.filter(
          (cat) => cat.category_name === category,
        );

        if (!isEmpty(selectedCategory[0])) {
          if (
            Number(item_limit_per_day) !== 0 &&
            selectedCategory[0].category_limit_per_day > 0 &&
            Number(item_limit_per_day) >
              selectedCategory[0].category_limit_per_day
          ) {
            errors["item_limit_per_day"] =
              "Item limit cannot be more than category limit";
          } else if (
            Number(item_limit_per_day) === 0 ||
            Number(item_limit_per_day) > Number(item_limit_per_time_slot_batch)
          ) {
            delete errors["item_limit_per_day"];
            delete errors["item_limit_per_time_slot_batch"];
          } else if (item_limit_per_time_slot_batch > item_limit_per_day) {
            errors["item_limit_per_time_slot_batch"] =
              "Value is more than daily limit";
          }
          state.errors = errors;
        } else {
          delete errors["item_limit_per_day"];
          values.item_limit_per_day = 0;
          const validateInput = this.validateLimitInput(value);

          values.item_limit_per_day = parseInt(validateInput);
          state.values = values;
        }
      } else {
        errors["item_limit_per_day"] = "Invalid input";
        state.errors = errors;
      }
    } else if (name === "discount_toggle") {
      values[name] = checked;
      state.values = values;
      this.computeDiscount();
    } else if (name === "isMealPlan") {
      values[name] = checked;

      if (checked) {
        // If item is set to meal plan, reset limits to 0
        values.item_limit_per_time_slot_batch = 0;
        values.item_limit_per_time_slot = getDefaultTimeSlots(this.props.store);
        values.item_limit_per_day = 0;
      }

      state.values = values;
    } else if (name === "isBulk") {
      values[name] = checked;
      state.values = values;
    } else if (name === "isUnavailableToday") {
      const { today_date = "" } = this.state;

      if (checked) {
        values["off_dates"].push(today_date);
      } else {
        const x = values["off_dates"].indexOf(today_date);

        if (x !== -1) {
          values["off_dates"].splice(x, 1);
        }
      }
      values[name] = checked;
      state.values = values;
    } else if (name === "isVisibleOnOffDate") {
      values[name] = checked;
      state.values = values;
    } else if (name === "isDineInExclusive") {
      values[name] = checked;
      if (this.state.values.isDineInExclusive) {
        values.exclusives.push("isDineInExclusive");
      } else {
        values.exclusives = values.exclusives.filter(
          (item) => item !== "isDineInExclusive",
        );
      }
      state.values = values;
    }

    this.setState(state);
  };

  setTagSettings = (args) => {
    this.setState((prevState) => {
      const newValues = { ...prevState.values };
      newValues.tag_settings = { ...args };
      return { values: newValues };
    });
  };

  setActiveTags = (args) => {
    this.setState((prevState) => {
      const newValues = { ...prevState.values };
      newValues.tags = [...args];
      return { values: newValues };
    });
  };

  setApplyToAll = (bool) => {
    this.setState((prevState) => {
      const newValues = { ...prevState.values };
      newValues.apply_to_all = bool;
      return { values: newValues };
    });
  };

  onSubmit = (values, setSubmitting) => {
    if (this.state.file) {
      this.props.uploadItemPhoto(
        { file: this.state.file, itemId: this.state.values._id },
        (filePath) => {
          values.image_url = filePath;
          this.setState({ values });
          setSubmitting(true);
          this.props.onSubmit(values, setSubmitting, this.afterSubmit);
        },
        setSubmitting,
      );
    } else {
      this.props.onSubmit(values, setSubmitting, this.afterSubmit);
    }
  };

  afterSubmit = () => {
    this.props.afterSubmit && this.props.afterSubmit();
  };
  onCheckBoxChange = (i, event) => {
    const target = event.target,
      // checked = target.checked,
      _id = target.getAttribute("_id");

    let { values } = this.state;
    let { extragroup = [] } = values;

    const group_index = extragroup.reduce(
      (acc, x, i) => (x.extra_group_id._id === _id ? [...acc, i] : acc),
      [],
    );

    //not in groups
    if (isEmpty(group_index)) {
      extragroup.push({
        extra_group_id: { _id: _id },
      });
    } else {
      group_index.map((idx) => extragroup.splice(idx, 1));
    }
    values["extragroup"] = extragroup;
    this.setState({ values });
    this.updateChanges(
      "extra_groups",
      extragroup.length +
        (extragroup.length === 1 ? " checked item" : " checked items"),
    );
  };
  CheckExtrasGroup = (extras) => {
    let { values = {} } = this.state;
    let { extragroup = [] } = values;
    return extras.map((extra) => {
      const group_index = extragroup.findIndex(
        (x) => x.extra_group_id._id === extra._id,
      );
      if (group_index === -1) {
        return { ...extra, checked: false };
      } else {
        return { ...extra, checked: true };
      }
    });
  };

  initializeRegularPrice = () => {
    // regular_price will initialize at the value of price if null
    let { values = {} } = this.state;
    if (values.regular_price === "") {
      values.regular_price = values.price;
      this.setState({ values });
      console.log("Initialize regular price: ", values.regular_price);
    }
  };

  onChangeRegularPrice = (e) => {
    let { values = {} } = this.state;
    values.regular_price = e.target.value;
    this.setState({ values });
    this.computeDiscount();
    this.updateChanges("regular_price", e.target.value);
    this.setStateKey("touched", "regular_price", "true");
    this.validateForm();
  };

  onChangeDiscountType = (e) => {
    let { values = {} } = this.state;
    values.discount_type = e.target.value;
    values.discount_value = 0;
    this.setState({ values });
    this.computeDiscount();
    this.updateChanges("discount_type", e.target.value);
  };

  onChangeDiscountValue = (e) => {
    let { values = {} } = this.state;
    values.discount_value = e.target.value;
    this.setState({ values });
    this.computeDiscount();
    this.updateChanges("discount_value", e.target.value);
  };

  onBlurPrices = (e) => {
    let { values = {} } = this.state;
    values.price = Number(values.price).toFixed(2);
    values.regular_price = Number(values.regular_price).toFixed(2);
    values.active_discount = Number(values.active_discount).toFixed(2);
    values.discounted_price = Number(values.discounted_price).toFixed(2);
    this.setStateKey("touched", "regular_price", "true");
    this.validateForm();
  };

  computeDiscount = () => {
    let { values = {} } = this.state;
    if (values.discount_toggle === false) {
      values.active_discount = 0;
      values.discount_value = 0;
      values.discount_type = "noDiscount";
      values.discount_description = "";
    } else {
      if (values.discount_type === "percentDiscount") {
        values.active_discount = Math.round(
          Number(values.discount_value * 0.01) * Number(values.regular_price),
        );
      } else if (values.discount_type === "rawDiscount") {
        values.active_discount = Number(values.discount_value);
      }
    }
    this.validateDiscount();
    values.discounted_price = values.regular_price - values.active_discount;
    values.price = values.discounted_price;
    this.setState({ values });
  };

  validateDiscount = () => {
    let { values = {} } = this.state;
    if (values.active_discount > values.regular_price)
      values.active_discount = 0;
    this.setState({ values });
  };

  componentDidMount() {
    this.initializeRegularPrice();
  }

  updateChanges = (name, value, event = "") => {
    if (
      [
        "available",
        "visible",
        "discount_toggle",
        "isUnavailableToday",
        "isVisibleOnOffDate",
        "isMealPlan",
        "isBulk",
      ].includes(name)
    ) {
      if (event) {
        value = event.target.checked ? "ON" : "OFF";
      }
    }
    this.setState((prevState) => ({
      ...prevState,
      changedValues: [
        ...prevState.changedValues.filter((e) => e.name !== name),
        { name, value },
      ],
    }));
    // console.log('changedValues', this.state.changedValues);
  };

  render() {
    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      };
    // const { noCategory = false, noAvailability = true, hotItem = false, extras = [], origin = "" } = this.props;
    const {
      noCategory = false,
      noAvailability = true,
      extras = [],
      origin = "",
      maxFileSize = 200,
      maxFileSizeText = "200KB",
      extraGroupErr,
    } = this.props;
    this.itemModalRef = React.createRef();
    this.limitFieldRef = React.createRef();
    this.extraGroupRef = React.createRef();

    return (
      <>
        <Form
          className="fv-plugins-bootstrap fv-plugins-framework"
          onSubmit={(event) => {
            event.preventDefault();
            setTimeout(() => {
              const { errors } = this.state;
              const keyErrors = Object.keys(errors);
              const itemInfoErr = [
                "name",
                "category",
                "image_url",
                "regular_price",
              ].filter((key) => keyErrors.includes(key));
              const limitErr = [
                "item_limit_per_time_slot_batch",
                "item_limit_per_day",
              ].filter((key) => keyErrors.includes(key));
              if (itemInfoErr.length > 0) {
                this.itemModalRef.current.scrollIntoView({
                  x: 0,
                  y: 0,
                  behaviour: "smooth",
                });
              }
              if (limitErr.length > 0) {
                this.limitFieldRef.current.scrollIntoView({
                  x: 0,
                  y: 0,
                  behaviour: "smooth",
                });
              }
              this.setAllFieldsToTouched();
              const isValid = this.validateForm();
              if (isEmpty(isValid)) {
                this.handleOnSubmit(event);
              }
            }, 50);
          }}
        >
          <div
            style={{
              maxHeight: `calc(100vh - 20rem)`,
              overflow: "scroll",
              overflowX: "hidden",
            }}
          >
            <Row ref={this.itemModalRef}>
              <Col lg={3} className="text-center">
                {this.state.values.image_url ? (
                  <>
                    <BrandLogo
                      style={{ width: "150px" }}
                      alt={"Item Photo"}
                      src={this.state.values.image_url}
                      onClickRemove={() => {
                        let { values = {} } = this.state;
                        values.image_url = "";
                        this.setState({ file: null, values });
                        this.updateChanges("image", "Removed");
                      }}
                    />
                  </>
                ) : (
                  <ImageUpload
                    onError={this.props.actions.onError}
                    onChange={(file, preview) => {
                      let { values = {} } = this.state;
                      values.image_url = preview;
                      values.image_updated = true;

                      this.setState({ file, values });
                      this.updateChanges("image", "Changed");
                    }}
                    label={
                      <div style={{ margin: "1rem 0" }}>
                        <Label
                          text={
                            <>
                              <span>
                                {" "}
                                Item Photo <RequiredAsterisk />{" "}
                              </span>
                            </>
                          }
                        />
                        <div
                          className="text-muted"
                          style={{ fontSize: "0.8rem" }}
                        >
                          Maximum file size is 25MB. <br />
                          Suggested dimensions is 250x250 or 200x50.
                        </div>
                        <div
                          className="text-danger"
                          style={{ fontSize: "0.8rem" }}
                        >
                          {this.handleFeedbackError("image_url", "error-text")}
                        </div>
                      </div>
                    }
                    maxFileSize={maxFileSize}
                    maxFileSizeText={maxFileSizeText}
                  />
                )}
              </Col>
              <Col lg={9} className="mw-72p">
                <FormItem
                  label={"Name"}
                  name={"name"}
                  inputProps={{
                    name: "name",
                    placeholder: "Name",
                    value: this.state.values["name"],
                  }}
                  inputActions={inputActions}
                  actions={actions}
                  type={"text"}
                  showRequired={true}
                  normal
                />
                <FormItem
                  customFormControl
                  label={"Description"}
                  name={"description"}
                  actions={actions}
                  custom={
                    <>
                      <Form.Control
                        as={"textarea"}
                        name="description"
                        placeholder="Description"
                        value={this.state.values["description"]}
                        {...inputActions}
                      />
                      <span className="form-text text-muted text-right">
                        {this.state.itemDescriptionCharCount}/
                        {ITEM_DESCRIPTION_CHAR_LIMIT}
                      </span>
                    </>
                  }
                  normal
                />
                <Row>
                  <Col sm={6}>
                    <FormItem
                      label={"Regular Price"}
                      name={"regular_price"}
                      inputProps={{
                        name: "regular_price",
                        placeholder: "Regular Price",
                        value: this.state.values["regular_price"],
                      }}
                      inputActions={{
                        ...inputActions,
                        onChange: this.onChangeRegularPrice,
                        onBlur: this.onBlurPrices,
                      }}
                      actions={actions}
                      type={"number"}
                      showRequired={true}
                      normal
                    />
                  </Col>
                  {/* <Col sm={6}>
                                        <FormItem
                                            label={"Published Price"}
                                            name={"price"}
                                            inputProps={{
                                                name: "price",
                                                placeholder: "Price",
                                                value: this.state.values["price"],
                                                disabled: "disabled"
                                            }}
                                            inputActions={inputActions}
                                            actions={actions}
                                            type={"text"}
                                            showRequired={false}
                                            normal
                                        />
                                    </Col> */}
                  <Col sm={6}>
                    {!noCategory ? (
                      <FormItem
                        customFormControl
                        label={"Category"}
                        name={"category"}
                        className={"form-control-solid"}
                        actions={actions}
                        type={"text"}
                        showRequired={true}
                        custom={
                          <>
                            <Typeahead
                              placeholder={"Category"}
                              onChange={(selected) => {
                                let {
                                  values = {},
                                  errors,
                                  isLimitEnabled,
                                } = this.state;
                                const { store } = this.props;
                                const { categories } = store;
                                const restrictedchars = ["$", "."];
                                let newValue = "";
                                if (
                                  selected !== undefined &&
                                  selected.length > 0
                                ) {
                                  newValue =
                                    typeof selected[0] === "string"
                                      ? selected[0]
                                      : selected[0].label;
                                  values.category = newValue;
                                  const labelSplit =
                                    values.category.split("") || [];

                                  for (const char of restrictedchars) {
                                    if (labelSplit.includes(char)) {
                                      errors["category"] =
                                        '"$" and "." is not allowed';
                                      break;
                                    }

                                    if (errors["category"])
                                      delete errors["category"];
                                  }
                                  if (
                                    isLimitEnabled &&
                                    values.item_limit_per_day > 0
                                  ) {
                                    const selectedCategory = categories.filter(
                                      (cat) =>
                                        cat.category_name === selected[0],
                                    );
                                    if (
                                      selectedCategory[0] &&
                                      selectedCategory[0]
                                        .category_limit_per_day <
                                        values.item_limit_per_day
                                    ) {
                                      errors["item_limit_per_day"] =
                                        "Item limit cannot be more than category limit";
                                    }
                                  } else {
                                    delete errors["item_limit_per_day"];
                                  }

                                  this.setState({ values, errors });
                                } else {
                                  values.category = "";
                                  this.setState({ values });
                                }

                                this.updateChanges("category", newValue);
                              }}
                              options={this.props.categories}
                              allowNew={true}
                              id={"categories_ta"}
                              onBlur={this.handleOnBlurCategory}
                              isValid={Boolean(
                                this.isTouched("category") &&
                                  this.getError("category") === "",
                              )}
                              isInvalid={this.getError("category") !== ""}
                              defaultInputValue={this.state.values.category}
                              newSelectionPrefix={"New Category: "}
                              emptyLabel={
                                <div style={{ whiteSpace: "break-spaces" }}>
                                  No matches found - try adding a new category
                                  by typing one
                                </div>
                              }
                            />
                          </>
                        }
                      />
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
                <Row>
                  {/* {
                                        this.state.values.discount_toggle === true
                                        ?<>
                                            <Col sm={6}>
                                                <FormItem
                                                    label={"Discounted Price"}
                                                    name={"discounted_price"}
                                                    inputProps={{
                                                        name: "discounted_price",
                                                        placeholder: "0.00",
                                                        value: this.state.values["discounted_price"],
                                                        disabled: "disabled"
                                                    }}
                                                    inputActions={inputActions}
                                                    actions={actions}
                                                    type={"number"}
                                                    normal
                                                />
                                            </Col>
                                        </>
                                        :<></>
                                    } */}
                  {/* <Col sm={6}>
                                        <FormItem
                                            label={"Regular Price"}
                                            name={"regular_price"}
                                            inputProps={{
                                                name: "regular_price",
                                                placeholder: "Regular Price",
                                                value: this.state.values["regular_price"],
                                            }}
                                            inputActions={{
                                                ...inputActions,
                                                onChange: this.onChangeRegularPrice,
                                                onBlur: this.onBlurPrices
                                            }}
                                            actions={actions}
                                            type={"number"}
                                            showRequired={true}
                                            normal
                                        />
                                    </Col> */}
                  {/* <Col sm={6}>
                                        <FormItem
                                            label={"Discount Amount"}
                                            name={"active_discount"}
                                            inputProps={{
                                                name: "active_discount",
                                                placeholder: "0.00",
                                                value: this.state.values["active_discount"],
                                                disabled: "disabled"
                                            }}
                                            inputActions={inputActions}
                                            actions={actions}
                                            type={"text"}
                                            showRequired={false}
                                            normal
                                        />
                                    </Col> */}
                </Row>
                <Card className="mb-5">
                  <Card.Body className="p-4">
                    <Row>
                      <Col sm={6} xs={12}>
                        <div>
                          <div className="d-flex align-items-center">
                            <label className="font-weight-bolder text-dark mr-5">
                              Discount:
                            </label>
                            <div className="pt-2">
                              <span className="switch switch-outline switch-icon switch-primary">
                                <label>
                                  <input
                                    type="checkbox"
                                    name="discount_toggle"
                                    checked={
                                      this.state.values.discount_toggle
                                        ? "checked"
                                        : null
                                    }
                                    {...inputActions}
                                  />
                                  <span></span>
                                </label>
                              </span>
                            </div>
                          </div>
                        </div>
                      </Col>
                      {this.state.values.discount_toggle === true ? (
                        <>
                          <Col sm={6} xs={12}>
                            <FormItem
                              label={"Discounted Price"}
                              name={"discounted_price"}
                              inputProps={{
                                name: "discounted_price",
                                placeholder: "0.00",
                                value: this.state.values["discounted_price"],
                                disabled: "disabled",
                              }}
                              inputActions={inputActions}
                              actions={actions}
                              type={"number"}
                              normal
                            />
                          </Col>
                        </>
                      ) : (
                        <></>
                      )}
                    </Row>
                  </Card.Body>
                  {!this.state.values.discount_toggle ? (
                    <></>
                  ) : (
                    <>
                      <Card.Body>
                        <Row>
                          <Col>
                            <div className="form-group">
                              <Label text="Discount Type" />
                              <div className="col-12 col-form-label">
                                <div
                                  className="radio-list"
                                  onChange={this.onChangeDiscountType}
                                >
                                  {/* <label className="radio">
                                                                        <input type="radio" id="none" name="discountType" value="noDiscount" checked={this.state.values.discount_type==='noDiscount'?'checked':''}/>
                                                                        <span></span>
                                                                        No Discount
                                                                    </label> */}
                                  <label className="radio">
                                    <input
                                      type="radio"
                                      id="percent"
                                      name="discountType"
                                      value="percentDiscount"
                                      checked={
                                        this.state.values.discount_type ===
                                        "percentDiscount"
                                          ? "checked"
                                          : ""
                                      }
                                    />
                                    <span></span>% Discount
                                  </label>
                                  <label className="radio">
                                    <input
                                      type="radio"
                                      id="raw"
                                      name="discountType"
                                      value="rawDiscount"
                                      checked={
                                        this.state.values.discount_type ===
                                        "rawDiscount"
                                          ? "checked"
                                          : ""
                                      }
                                    />
                                    <span></span>
                                    Discount Amount
                                  </label>
                                  {/* <label class="radio">
                                                                        <input type="radio" checked="checked" name="radios4"/>
                                                                        <span></span>
                                                                        Checked
                                                                    </label>
                                                                    <label class="radio radio-disabled">
                                                                        <input type="radio" disabled="disabled" name="radios4"/>
                                                                        <span></span>
                                                                        Disabled
                                                                    </label> */}
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col>
                            <FormItem
                              label={`Discount Type Value 
                                                                ${
                                                                  this.state
                                                                    .values
                                                                    .discount_type ===
                                                                  "percentDiscount"
                                                                    ? `(%)`
                                                                    : `${
                                                                        this
                                                                          .state
                                                                          .values
                                                                          .discount_type ===
                                                                        "rawDiscount"
                                                                          ? `(PHP)`
                                                                          : ``
                                                                      }`
                                                                }`}
                              name={"discount_value"}
                              inputProps={{
                                name: "discount_value",
                                placeholder: "0",
                                value: this.state.values["discount_value"],
                                disabled:
                                  this.state.values.discount_type ===
                                  "noDiscount"
                                    ? "disabled"
                                    : "",
                              }}
                              inputActions={{
                                ...inputActions,
                                onChange: this.onChangeDiscountValue,
                                onBlur: this.onBlurPrices,
                              }}
                              actions={actions}
                              type={"number"}
                              // showRequired={true}
                              normal
                            />
                            {this.state.values.discount_type ===
                              "percentDiscount" &&
                            Number(this.state.values.discount_value) > 100 ? (
                              <div className="text-danger">
                                Please make sure that your discount is less than
                                the regular price
                              </div>
                            ) : (
                              <></>
                            )}
                            {this.state.values.discount_type ===
                              "rawDiscount" &&
                            Number(this.state.values.discount_value) >
                              Number(this.state.values.regular_price) ? (
                              <div className="text-danger">
                                Please make sure that your discount is less than
                                the regular price
                              </div>
                            ) : (
                              <></>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormItem
                              customFormControl
                              label={"Discount Description"}
                              name={"discount_description"}
                              actions={actions}
                              // showRequired={true}
                              custom={
                                <>
                                  <Form.Control
                                    as={"textarea"}
                                    name="discount_description"
                                    placeholder="Discount Description"
                                    value={
                                      this.state.values["discount_description"]
                                    }
                                    {...inputActions}
                                  />
                                  <span className="form-text text-muted text-right">
                                    {this.state.discountDescriptionCharCount}/
                                    {ITEM_DESCRIPTION_CHAR_LIMIT}
                                  </span>
                                </>
                              }
                              normal
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormItem
                              label={"Regular Price"}
                              name={"regular_price"}
                              inputProps={{
                                name: "regular_price",
                                placeholder: "Regular Price",
                                value: this.state.values["regular_price"],
                                disabled: "disabled",
                              }}
                              inputActions={inputActions}
                              actions={actions}
                              type={"number"}
                              // showRequired={true}
                              normal
                              // helperText={<>Set to <b>0</b> if no limit.</>}
                            />
                          </Col>
                          <Col>
                            <FormItem
                              label={"Discount Amount"}
                              name={"active_discount"}
                              inputProps={{
                                name: "active_discount",
                                placeholder: "0.00",
                                value: this.state.values["active_discount"],
                                disabled: "disabled",
                              }}
                              inputActions={inputActions}
                              actions={actions}
                              type={"number"}
                              normal
                            />
                          </Col>
                          {/* <Col>
                                                        <FormItem
                                                            label={"Discounted Price"}
                                                            name={"discounted_price"}
                                                            inputProps={{
                                                                name: "discounted_price",
                                                                placeholder: "0.00",
                                                                value: this.state.values["discounted_price"],
                                                                disabled: "disabled"
                                                            }}
                                                            inputActions={inputActions}
                                                            actions={actions}
                                                            type={"number"}
                                                            normal
                                                        />
                                                    </Col> */}
                        </Row>
                      </Card.Body>
                    </>
                  )}
                </Card>
                {origin === "getting_started" ? (
                  <></>
                ) : (
                  <>
                    {!this.state.values.isMealPlan && (
                      <Row className="mb-5" ref={this.limitFieldRef}>
                        <Col>
                          <Label text="Limits" />
                          <Accordion defaultActiveKey="1">
                            <Card>
                              <Card.Header>
                                <Accordion.Toggle
                                  as={Button}
                                  variant="link"
                                  eventKey="0"
                                  onClick={() => {
                                    this.setState({
                                      isLimitEnabled:
                                        !this.state.isLimitEnabled,
                                    });
                                  }}
                                >
                                  View Limits
                                </Accordion.Toggle>
                              </Card.Header>
                              <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                  <InfoAlertHelper
                                    text={
                                      <>
                                        You can set the limits of this item on a
                                        daily or time slot basis. Set to{" "}
                                        <b>0</b> if no limit.
                                      </>
                                    }
                                  />
                                  <Row>
                                    <Col>
                                      <FormItem
                                        label={"Daily Limit"}
                                        name={"item_limit_per_day"}
                                        inputProps={{
                                          name: "item_limit_per_day",
                                          placeholder: "Daily Limit",
                                          value:
                                            this.state.values[
                                              "item_limit_per_day"
                                            ],
                                          // disabled: "disabled"
                                        }}
                                        inputActions={inputActions}
                                        actions={actions}
                                        type={"number"}
                                        // showRequired={true}
                                        normal
                                        // helperText={<>Set to <b>0</b> if no limit.</>}
                                      />
                                    </Col>
                                    <Col>
                                      <FormItem
                                        label={"Per Time Slot Limit"}
                                        name={"item_limit_per_time_slot_batch"}
                                        inputProps={{
                                          name: "item_limit_per_time_slot_batch",
                                          placeholder: "Per Time Slot Limit",

                                          value:
                                            this.state.values[
                                              "item_limit_per_time_slot_batch"
                                            ],
                                        }}
                                        inputActions={inputActions}
                                        actions={actions}
                                        type={"number"}
                                        normal
                                      />
                                    </Col>
                                  </Row>
                                  {/* <Label text={<>Time Slot Limits</>}/> 
                                                                <TimeSlotLimitsForm 
                                                                    time_slot_limits={this.state.values["item_limit_per_time_slot"]}
                                                                    store={store}
                                                                    onChange={(item_limit_per_time_slot) => {
                                                                        let { values = {} } = this.state;
                                                                        values.item_limit_per_time_slot = item_limit_per_time_slot;

                                                                        this.setState({ values });
                                                                    }}  
                                                                /> */}
                                  {/* <Row>
                                                                    <Col sm={9}>
                                                                        <FormItem
                                                                            name={"general_slot_limit"}
                                                                            inputProps={{
                                                                                name: "general_slot_limit",
                                                                                placeholder: "Standard Slot Limit",
                                                                                value: this.state.values["general_slot_limit"],
                                                                                // disabled: "disabled"
                                                                            }}
                                                                            inputActions={inputActions}
                                                                            actions={actions}
                                                                            type={"number"}
                                                                            // showRequired={true}
                                                                            normal
                                                                            noLabel
                                                                            // helperText={<>Set to <b>0</b> if no limit.</>}
                                                                        /> 
                                                                    </Col>
                                                                    <Col sm={3}>
                                                                        <Button variant="primary" className="btn-block">Apply To All</Button>
                                                                    </Col>
                                                                </Row> */}
                                  {/* <Row>
                                                                    <Col>
                                                                        <Table>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Time Slot</th>
                                                                                    <th>Limit</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>7:00 - 7:30 AM</td>
                                                                                    <td>
                                                                                        <FormItem
                                                                                            name={"item_limit_per_day"}
                                                                                            inputProps={{
                                                                                                name: "item_limit_per_day",
                                                                                                placeholder: "Standard Slot Limit",
                                                                                                value: this.state.values["item_limit_per_day"],
                                                                                                // disabled: "disabled"
                                                                                            }}
                                                                                            inputActions={inputActions}
                                                                                            actions={actions}
                                                                                            type={"number"}
                                                                                            // showRequired={true}
                                                                                            normal
                                                                                            noLabel
                                                                                            // helperText={<>Set to <b>0</b> if no limit.</>}
                                                                                        /> 
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>7:00 - 7:30 AM</td>
                                                                                    <td>
                                                                                        <FormItem
                                                                                            name={"item_limit_per_day"}
                                                                                            inputProps={{
                                                                                                name: "item_limit_per_day",
                                                                                                placeholder: "Standard Slot Limit",
                                                                                                value: this.state.values["item_limit_per_day"],
                                                                                                // disabled: "disabled"
                                                                                            }}
                                                                                            inputActions={inputActions}
                                                                                            actions={actions}
                                                                                            type={"number"}
                                                                                            // showRequired={true}
                                                                                            normal
                                                                                            noLabel
                                                                                            // helperText={<>Set to <b>0</b> if no limit.</>}
                                                                                        /> 
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </Table>
                                                                    </Col>
                                                                </Row> */}
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                          </Accordion>
                        </Col>
                      </Row>
                    )}
                    <Row className="mb-5" ref={this.props.extraGroupRef}>
                      <Col lg={12}>
                        <Label text="Extra Groups" />
                        <Accordion defaultActiveKey="1">
                          <Card>
                            <Card.Header>
                              <Accordion.Toggle
                                as={Button}
                                variant="link"
                                eventKey="0"
                              >
                                View Extra Groups
                              </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                              <Card.Body>
                                <ListGroup>
                                  {/* <DragDropContext onDragEnd={this.onDragEnd}>
                                                                    <Droppable droppableId={"groups"} type="CATEGORY">
                                                                        {(provided, snapshot) => (
                                                                            <div ref={provided.innerRef}>
                                                                                {
                                                                                    this.CheckExtrasGroup(extras).map((extra, index) => {
                                                                                        return (
                                                                                            <Draggable
                                                                                                key={extra.id}
                                                                                                draggableId={"group-" + index}
                                                                                                index={index}
                                                                                                type="CATEGORY"
                                                                                            >
                                                                                                {(provided, snapshot) => (
                                                                                                    <div ref={provided.innerRef}  {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                                                    <ListGroup.Item key={index}>
                                                                                <Row>
                                                                                    <Col lg={1}>
                                                                                        <Checkbox color="default" className={"py-4"} inputProps={extra} checked={extra.checked} onChange={this.onCheckBoxChange.bind(this, index)} />
                                                                                    </Col>
                                                                                    <Col lg={7}>
                                                                                        <label className={"py-4"}>{extra.name}</label>
                                                                                    </Col>
                                                                                    <Col lg={2}>
                                                                                        <label className={"py-4"}> <span className="label label-warning label-inline font-weight-bold mr-2"> {extra.maximum_number} MAXIMUM</span></label>
                                                                                    </Col>
                                                                                    <Col lg={2}>
                                                                                        <label className={"py-4"}>
                                                                                            {
                                                                                                (extra.minimum_number === 0) ? <span className="label label-primary label-inline font-weight-bold mr-2">OPTIONAL</span> :
                                                                                                    <span className="label label-info label-inline font-weight-bold mr-2">{extra.minimum_number} REQUIRED</span>
                                                                                            }

                                                                                        </label>
                                                                                    </Col>

                                                                                </Row>
                                                                            </ListGroup.Item>
                                                                                                    </div>
                                                                                                )}
                                                                                            </Draggable>
                                                                                        )
                                                                                    })
                                                                                }
                                                                                {provided.placeholder}
                                                                            </div>
                                                                        )}
                                                                    </Droppable>
                                                                </DragDropContext> */}
                                  {this.CheckExtrasGroup(extras).map(
                                    (extra, i) => {
                                      const isExtraGroupErr =
                                        extraGroupErr.filter(
                                          (item) =>
                                            item === extra.extra_group_code,
                                        )[0];

                                      const labelStyle = !isEmpty(
                                        isExtraGroupErr,
                                      )
                                        ? { color: "red", fontWeight: 700 }
                                        : {};

                                      return (
                                        <ListGroup.Item key={i}>
                                          <Row>
                                            <Col lg={1}>
                                              <Checkbox
                                                color="default"
                                                className={"py-4"}
                                                inputProps={extra}
                                                checked={extra.checked}
                                                onChange={this.onCheckBoxChange.bind(
                                                  this,
                                                  i,
                                                )}
                                              />
                                            </Col>
                                            <Col lg={7}>
                                              <label
                                                className={"py-4"}
                                                style={labelStyle}
                                              >
                                                {extra.name}
                                              </label>
                                            </Col>
                                            <Col lg={4}>
                                              <Row>
                                                <label className={"py-4"}>
                                                  {" "}
                                                  <span
                                                    style={{ width: "6.5rem" }}
                                                    className="label label-warning label-inline font-weight-bold mr-3"
                                                  >
                                                    {" "}
                                                    {extra.maximum_number}{" "}
                                                    MAXIMUM
                                                  </span>
                                                </label>
                                                <label className={"py-4"}>
                                                  {extra.minimum_number ===
                                                  0 ? (
                                                    <span className="label label-primary label-inline font-weight-bold">
                                                      OPTIONAL
                                                    </span>
                                                  ) : (
                                                    <span className="label label-info label-inline font-weight-bold">
                                                      {extra.minimum_number}{" "}
                                                      REQUIRED
                                                    </span>
                                                  )}
                                                </label>
                                              </Row>
                                            </Col>
                                          </Row>
                                        </ListGroup.Item>
                                      );
                                    },
                                  )}
                                </ListGroup>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      </Col>
                    </Row>
                  </>
                )}
                <br />
                <Card className="mb-5">
                  <Card.Body className="p-4">
                    <Row>
                      {noAvailability ? (
                        <></>
                      ) : (
                        <>
                          <Col sm={6} xs={12}>
                            <div>
                              <div className="d-flex align-items-center">
                                <label className="font-weight-bolder text-dark mr-5">
                                  Available:
                                </label>
                                <div>
                                  <span className="switch switch-outline switch-icon switch-primary">
                                    <label>
                                      <input
                                        type="checkbox"
                                        name="available"
                                        checked={
                                          this.state.values.available
                                            ? "checked"
                                            : null
                                        }
                                        {...inputActions}
                                      />
                                      <span></span>
                                    </label>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Col>
                          {this.state.showVisibilityToggle && (
                            <Col sm={6} xs={12}>
                              <div>
                                <div className="d-flex align-items-center">
                                  <label className="font-weight-bolder text-dark mr-5">
                                    Show in store:
                                  </label>
                                  <div>
                                    <span className="switch switch-outline switch-icon switch-primary">
                                      <label>
                                        <input
                                          type="checkbox"
                                          name="visible"
                                          checked={
                                            this.state.values.visible
                                              ? "checked"
                                              : null
                                          }
                                          {...inputActions}
                                        />
                                        <span></span>
                                      </label>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          )}
                        </>
                      )}
                    </Row>
                  </Card.Body>
                </Card>

                <Card className="mb-5">
                  <Card.Body className="p-4">
                    <Row>
                      {noAvailability ? (
                        <></>
                      ) : (
                        <>
                          <Col sm={6} xs={12}>
                            <div>
                              <div className="d-flex align-items-center">
                                <label className="font-weight-bolder text-dark mr-5">
                                  Meal Plan Item:
                                </label>
                                <div>
                                  <span className="switch switch-outline switch-icon switch-primary">
                                    <label>
                                      <input
                                        type="checkbox"
                                        name="isMealPlan"
                                        checked={
                                          this.state.values.isMealPlan
                                            ? "checked"
                                            : null
                                        }
                                        {...inputActions}
                                      />
                                      <span></span>
                                    </label>
                                  </span>
                                </div>
                                {/* <div className="d-flex align-items-center justify-content-center ml-5">
                                                                        {InfoTooltip("Only Meal Plan Items are shown for Meal Plan orders")}
                                                                    </div> */}
                                <div
                                  className="text-muted ml-5"
                                  style={{ fontSize: "0.8rem" }}
                                >
                                  Only Meal Plan Items are shown for Meal Plan
                                  orders
                                </div>
                              </div>
                            </div>
                          </Col>
                        </>
                      )}
                    </Row>
                  </Card.Body>
                </Card>

                <Card className="mb-5">
                  <Card.Body className="p-4">
                    <Row>
                      {noAvailability ? (
                        <></>
                      ) : (
                        <>
                          <Col sm={6} xs={12}>
                            <div>
                              <div className="d-flex align-items-center">
                                <label className="font-weight-bolder text-dark mr-5">
                                  Bulk Item:
                                </label>
                                <div>
                                  <span className="switch switch-outline switch-icon switch-primary">
                                    <label>
                                      <input
                                        type="checkbox"
                                        name="isBulk"
                                        checked={
                                          this.state.values.isBulk
                                            ? "checked"
                                            : null
                                        }
                                        {...inputActions}
                                      />
                                      <span></span>
                                    </label>
                                  </span>
                                </div>
                                {/* {InfoTooltip("Bulk items can only be delivered by cars")} */}
                                <div
                                  className="text-muted ml-5"
                                  style={{ fontSize: "0.8rem" }}
                                >
                                  Bulk items can only be delivered by cars
                                </div>
                              </div>
                            </div>
                          </Col>
                        </>
                      )}
                    </Row>
                  </Card.Body>
                </Card>

                {noAvailability || this.state.values.isMealPlan ? (
                  <></>
                ) : (
                  <Card className="mb-5">
                    <Card.Body className="p-4">
                      <Row>
                        <Col sm={6} xs={12}>
                          <div>
                            <div className="d-flex align-items-center">
                              <label className="font-weight-bolder text-dark mr-5">
                                Mark as unavailable for today:
                              </label>
                              <div>
                                <span className="switch switch-outline switch-icon switch-primary">
                                  <label>
                                    <input
                                      type="checkbox"
                                      name="isUnavailableToday"
                                      checked={
                                        this.state.values.isUnavailableToday
                                          ? "checked"
                                          : null
                                      }
                                      disabled={!this.state.values.available}
                                      {...inputActions}
                                    />
                                    <span></span>
                                  </label>
                                </span>
                              </div>
                              <div
                                className="text-muted"
                                style={{ fontSize: "0.8rem" }}
                              >
                                This will mark this item as unavailable for
                                today,{" "}
                                {moment(
                                  this.state.today_date,
                                  DB_DATE_FORMAT,
                                ).format("MMM D, YYYY")}
                                .
                              </div>
                            </div>
                          </div>
                        </Col>
                        {this.state.values.isUnavailableToday ? (
                          <Col sm={6} xs={12}>
                            <div>
                              <div className="d-flex align-items-center">
                                <label className="font-weight-bolder text-dark mr-5">
                                  Show in store:
                                </label>
                                <div>
                                  <span className="switch switch-outline switch-icon switch-primary">
                                    <label>
                                      <input
                                        type="checkbox"
                                        name="isVisibleOnOffDate"
                                        checked={
                                          this.state.values.isVisibleOnOffDate
                                            ? "checked"
                                            : null
                                        }
                                        {...inputActions}
                                      />
                                      <span></span>
                                    </label>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Col>
                        ) : (
                          <></>
                        )}
                      </Row>
                    </Card.Body>
                  </Card>
                )}

                <Card className="mb-5">
                  <Card.Body className="p-4">
                    <Row>
                      {noAvailability ? (
                        <></>
                      ) : (
                        <>
                          <Col sm={6} xs={12}>
                            <div>
                              <div className="d-flex align-items-center">
                                <label className="font-weight-bolder text-dark mr-5">
                                  Dine in Exclusive:
                                </label>
                                <div>
                                  <span className="switch switch-outline switch-icon switch-primary">
                                    <label>
                                      <input
                                        type="checkbox"
                                        name="isDineInExclusive"
                                        checked={
                                          this.state.values.isDineInExclusive
                                            ? "checked"
                                            : null
                                        }
                                        {...inputActions}
                                      />
                                      <span></span>
                                    </label>
                                  </span>
                                </div>
                                <div
                                  className="text-muted"
                                  style={{ fontSize: "0.8rem" }}
                                >
                                  This item will be available only on dine-in.
                                </div>
                              </div>
                            </div>
                          </Col>
                        </>
                      )}
                    </Row>
                  </Card.Body>
                </Card>

                <Card>
                  <div className="form-group row mv-1rem">
                    <label className="col-4 col-sm-5 col-xl-2 col-form-label font-weight-bolder text-dark mr-2">
                      Tags:
                    </label>
                  </div>
                  <ItemTags
                    setTagSettings={this.setTagSettings}
                    setActiveTags={this.setActiveTags}
                    tags={this.state.values.tags}
                    tagSettings={this.state.values.tag_settings}
                    updateChanges={this.updateChanges}
                  />
                </Card>
                <br />
              </Col>
            </Row>
          </div>

          <div className="offset-lg-3 col-lg-9">
            <div className="d-flex flex-wrap justify-content-between align-items-center">
              <div>
                <br />
                {this.props.role !== "console_user" ? (
                  <ApplyToAllField
                    changes={this.state.changedValues}
                    storeCount={this.props.store.store_count}
                    setApplyToAll={this.setApplyToAll}
                    method={this.props.method}
                  />
                ) : null}
                <br />
                <button
                  type="submit"
                  disabled={this.state.isSubmitting}
                  className={GetSubmitClassList(
                    this.state.isSubmitting,
                    `btn btn-primary font-weight-bold px-9 py-4 my-3`,
                  )}
                >
                  <span>
                    {this.state.values._id === "" ? "Add item" : "Save changes"}
                  </span>
                </button>
                {this.props.showDeleteButton ? (
                  <button
                    type="button"
                    disabled={this.props.deleteLoading}
                    className={GetSubmitClassList(
                      this.props.deleteLoading,
                      "btn btn-danger font-weight-bold px-9 py-4 my-3 ml-2",
                    )}
                    onClick={this.handleOnClickDeleteBtn}
                  >
                    <span>Delete</span>
                  </button>
                ) : (
                  <></>
                )}
              </div>
              <div>
                {this.props.showCancelButton ? (
                  <button
                    type="button"
                    disabled={this.state.isSubmitting}
                    className={
                      "btn btn-secondary font-weight-bold px-9 py-4 my-3 ml-2"
                    }
                    onClick={this.handleOnClickCancelBtn}
                  >
                    <span>Cancel</span>
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </Form>
      </>
    );
  }
}
