import React, { useEffect } from "react";
import { Switch, Route, withRouter } from "react-router-dom";

import About from "./About";
import AboutStore from "./AboutStore";
import CancelledOrderPage from "./CancelledOrder";
import Checkout from "./Checkout";
import ComingSoon from "./ComingSoon";
import Homepage from "./Homepage";
import Menu2 from "./Menu2";
// import DineIn from "./DineInTable";
import NotFoundPage from "./NotFoundPage";
import Payments from "./Payments";
import RetryPayment from "./RetryPayment";
import ExternalPayment from "./ExternalPayment";
import ExternalRetryPayment from "./ExternalRetryPayment";
import ExternalFailedPayment from "./ExternalPaymentFailed";
import ExternalPaymentPending from "./ExternalPaymentPending";
import PaymentFailed from "./PaymentFailed";
import PaymentPending from "./PaymentPending";
import Track from "./Track";
import ExternalTrackPage from "./ExternalTrack";
import SessionExpired from "./SessionExpired";

function Routes(props) {
  return (
    <>
      <Switch>
        <Route path="/about">
          <About {...props} />
        </Route>

        <Route path="/cancelled-order">
          <CancelledOrderPage {...props} />
        </Route>

        <Route path="/checkout">
          <Checkout {...props} />
        </Route>

        <Route path="/store-checkout/:backup_id">
          <Checkout {...props} />
        </Route>

        <Route path="/coming-soon">
          <ComingSoon {...props} />
        </Route>

        <Route path="/failed">
          <PaymentFailed {...props} />
        </Route>

        <Route path="/menu">
          <Menu2 {...props} />
        </Route>

        <Route path="/in-store-order/:store_user">
          <Menu2 {...props} />
        </Route>

        <Route path="/table/:table_number">
          {/* <DineIn {...props}/> */}
          <Menu2 {...props} />
        </Route>

        <Route path="/tableqr/:storetable_id">
          {/* <DineIn {...props}/> */}
          <Menu2 {...props} />
        </Route>

        <Route path="/not-found">
          <NotFoundPage {...props} />
        </Route>

        <Route path="/payments">
          <Payments {...props} />
        </Route>

        <Route path="/pending">
          <PaymentPending {...props} />
        </Route>

        <Route path="/retry-payment">
          <RetryPayment {...props} />
        </Route>

        <Route path="/external-retry-payments">
          <ExternalRetryPayment {...props} />
        </Route>

        <Route path="/external-payments">
          <ExternalPayment {...props} />
        </Route>

        <Route path="/external-failed">
          <ExternalFailedPayment {...props} />
        </Route>

        <Route path="/external-pending">
          <ExternalPaymentPending {...props} />
        </Route>

        <Route path="/session-expired">
          <SessionExpired {...props} />
        </Route>

        <Route path="/track">
          <Track {...props} />
        </Route>

        <Route path="/external-track">
          <ExternalTrackPage {...props} />
        </Route>

        <Route path="/:store_url/about">
          {/* <About {...props} /> */}
          <AboutStore {...props} />
        </Route>

        <Route path="/:store_url/menu">
          {/* <Menu {...props}/> */}
          <Menu2 {...props} />
        </Route>

        <Route path="/:store_url/in-store-order/:store_user">
          {/* <Menu {...props}/> */}
          <Menu2 {...props} />
        </Route>

        <Route path="/:store_url/table/:table_number">
          {/* <DineIn {...props}/> */}
          <Menu2 {...props} />
        </Route>

        <Route path="/:store_url/tableqr/:storetable_id">
          {/* <DineIn {...props}/> */}
          <Menu2 {...props} />
        </Route>

        <Route path="/:store_url">
          <Homepage {...props} />
        </Route>

        <Route path="/">
          <Homepage {...props} />
        </Route>
      </Switch>
    </>
  );
}

export default withRouter(Routes);
