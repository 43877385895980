import socketIOClient from "socket.io-client";
import config from "../../vendors/core-api/core-config";
const environment = process.env.NODE_ENV || "development";
const SOCKET_ENDPOINT = config["socket"][environment];

console.log(`socket endpoint: ${SOCKET_ENDPOINT}`);

let storeListenerConnected = false;
const setConnection = (val) => {
  storeListenerConnected = val;
};
const socket = socketIOClient(SOCKET_ENDPOINT, {
  transports: ["websocket", "polling"],
});

const removeAllListeners = () => {
  socket.removeAllListeners();
};

export { socket, setConnection, storeListenerConnected, removeAllListeners };
