import React from "react";
import { useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import ProSourceForm from "../components/ProSourceForm";
import { Card, Row, Col, Button } from "react-bootstrap";
import { FormItem, InfoTooltip } from "../components/Base";
import { GetSubmitClassList } from "../helpers";
import core from "../vendors/core-api";

export default class MarketplaceSearchDiv extends ProSourceForm {
  constructor(props) {
    super(props);

    const { data } = this.props,
      { _id } = data;

    this.state = {
      searchTagsList: [],
      multiSelections: [],
      errors: {},
      touched: {},
      redirectTo: "",
      isSubmitting: false,
      isEditing: false,
    };

    this.api = core("pu").get();
    this.data_url = `/store/${_id}/search-tags-list`;
    this.onClickSubmit = this.onClickSubmit.bind(this);
    this.blockInvalidChars = this.blockInvalidChars.bind(this);
  }

  async getData() {
    if (this.data_url !== "") {
      const { iam_role = "" } = this.props;
      if (iam_role === "console_user") return;

      this._isMounted && this.setState({ data_status: "fetching" });
      this.api &&
        this.api
          .get(this.data_url)
          .then(({ data }) => {
            this.setState({
              data_status: "fetched",
              searchTagsList: data,
            });

            if (this.use_display_data) {
              this.setDisplayData();
            }
          })
          .catch((error) => {
            this.setState({ errors: error, showSwalError: true });
          });
    } else {
      this.afterComponentDidMount();
    }
  }

  async componentDidMount() {
    await this.getData();
  }

  componentDidUpdate() {
    // console.log("state", this.state);
    // console.log("props", this.props);
  }

  onClickSubmit = () => {
    let { data = {} } = this.props;
    this.props.onSubmit(data, (isSubmitting) => {
      this.setState({ isSubmitting });
    });
  };

  onClickEdit = () => {
    this.setState({ isEditing: true });
  };

  onClickCancelEdit = () => {
    this.setState({ isEditing: false });
  };

  blockInvalidChars = (e) => {
    const regex = /^[~@$^*()_+=[\]{}|\\/`'",.?!:; <>%&]*$/;
    if (regex.test(e.key)) {
      e.preventDefault();
    }
  };

  render() {
    const { title, multiple = true, data = {}, editable = false } = this.props;
    const { isSubmitting = false, searchTagsList = [], isEditing } = this.state;
    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      };

    return (
      <>
        <Card className="card-custom">
          <Card.Header>
            <Card.Title>
              {title}
              {InfoTooltip(
                "Keywords in Search Tags will help in marketplace search for your store.",
              )}
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col>
                <FormItem
                  label={"Search Tags"}
                  name={"search_tags"}
                  customFormControl
                  className={"form-control-solid"}
                  inputActions={inputActions}
                  actions={actions}
                  showRequired={false}
                  custom={
                    <>
                      <Choices
                        searchTagsList={searchTagsList}
                        preselected={data.search_tags}
                        multiple={multiple}
                        disabled={!isEditing}
                        blockInvalidChars={this.blockInvalidChars}
                        actions
                        {...this.props}
                      />
                    </>
                  }
                  helperText={
                    <span className="text-muted" style={{ fontSize: "0.9rem" }}>
                      Add search tags to improve your store's visibility, Enter
                      relevant keywords or phrases that describe your products
                      for better search results
                    </span>
                  }
                />
              </Col>
            </Row>
            {editable && (
              <Row>
                {this.state.isEditing ? (
                  <>
                    <Button
                      type="submit"
                      className={GetSubmitClassList(
                        isSubmitting,
                        "btn btn-primary font-weight-bold  px-5 py-2 my-1 mx-2",
                      )}
                      onClick={this.onClickSubmit}
                    >
                      Save Changes
                    </Button>
                    <Button
                      variant="danger"
                      className={
                        "btn btn-secondary font-weight-bold  px-5 py-2 my-1 mx-2"
                      }
                      onClick={this.onClickCancelEdit}
                    >
                      Cancel
                    </Button>
                  </>
                ) : (
                  <Button
                    className={
                      "btn btn-primary font-weight-bold  px-5 py-2 my-1 mx-2"
                    }
                    onClick={this.onClickEdit}
                  >
                    Edit
                  </Button>
                )}
              </Row>
            )}
          </Card.Body>
        </Card>
      </>
    );
  }
}

const Choices = ({
  searchTagsList,
  data,
  multiple,
  disabled,
  preselected,
  blockInvalidChars,
}) => {
  const [multiSelections, setMultiSelections] = useState(preselected);
  return (
    <Typeahead
      id={"search-tags"}
      placeholder={"Search Tags"}
      onChange={(selected) => {
        if (selected !== undefined) {
          const newSelected = selected.map((tag) => {
            return typeof tag === "string" ? tag : tag.name;
          });
          setMultiSelections(newSelected);
          data.search_tags = newSelected;
        }
      }}
      options={searchTagsList}
      selected={multiSelections}
      multiple={multiple}
      disabled={disabled}
      labelKey="name"
      allowNew={true}
      onKeyDown={(e) => blockInvalidChars(e)}
      newSelectionPrefix={"New Search Tag: "}
      emptyLabel={
        <div style={{ whiteSpace: "break-spaces" }}>
          No matches found - try adding a new tag by typing one
        </div>
      }
    />
  );
};
