import React, { Component } from "react";

import APIComponent from "../../components/API";
import core from "../../vendors/core-api";
import { useParams, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// import helpertexts from "../helpertexts";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import TitleHelmet from "../../partials/TitleHelmet";
import { HeaderTextWithActions, KTLink, EditIcon } from "../../components/Base";
import Breadcrumbs from "../../components/Breadcrumbs";
import { Card, Modal, Button, ListGroup, Row, Col } from "react-bootstrap";
import ItemListDnD from "../../partials/ItemListDnD";
import {
  GetDataFromEvent,
  filterObj,
  getDefaultTimeSlots,
} from "../../helpers";
import { PRIMARY_COLOR } from "../../constants";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ItemForm from "../../partials/forms/ItemForm";
import ExtrasForm from "../../partials/forms/ExtrasForm";
import CategoryForm from "../../partials/forms/CategoryForm";
import SelectionModal from "../../components/widgets/SelectionModal";
import ComingSoonDiv from "../../components/ComingSoonDiv";
import { isAccessAllowed } from "../../auth";
import { getUserByToken } from "../../modules/Auth/_redux/authCrud";
import { actionTypes } from "../../modules/Auth/_redux/authRedux";
import PacmanLoading from "../../components/widgets/PacmanLoading/PacmanLoading";

const MySwal = withReactContent(Swal);

export default function Page() {
  const dispatch = useDispatch();
  const refreshUserRedux = () => {
    getUserByToken()
      .then(({ data }) => {
        dispatch({ type: actionTypes.UserLoaded, payload: { user: data } });
      })
      .catch((err) => {
        console.log(err);
        MySwal.fire({
          icon: "error",
          title: "Ooops!",
          text: `Something went wrong`,
          confirmButtonColor: PRIMARY_COLOR,
          confirmButtonText: "OK",
          showCancelButton: false,
        });
      });
  };
  const params = useParams(),
    { user } = useSelector((state) => state.auth),
    { brand_url = "", store_id = "", mode = "" } = params;
  return isAccessAllowed(user, { brand_url, store_id }) ? (
    <MainComponent
      user={user}
      id={store_id}
      webview={mode === "webview"}
      {...params}
      refreshUserRedux={refreshUserRedux}
    />
  ) : (
    <Redirect to={"/error/error-v1"} />
  );
}

class MainComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      showSplashScreen: false,
      showMenu: true,
      showExtras: false,
      showUpsell: false,
      redirectTo: "",
      store_name: "",
      brand_name: "",
      visible: true,
    };

    this.onClickMainTab = this.onClickMainTab.bind(this);
  }

  onClickMainTab = (e) => {
    const tab = GetDataFromEvent(e, "data-tab");

    if (tab === "menu") {
      this.setState({ showMenu: true, showExtras: false, showUpsell: false });
    } else if (tab === "extras") {
      this.setState({ showMenu: false, showExtras: true, showUpsell: false });
    } else if (tab === "upsell") {
      this.setState({ showMenu: false, showExtras: false, showUpsell: true });
    }
  };

  render() {
    const page = {
      setPageTitle: (title) => {
        this.setState({ title });
      },
      setSplashScreen: (showSplashScreen) => {
        this.setState({ showSplashScreen });
      },
      setRedirectTo: (redirectTo) => {
        this.setState({ redirectTo });
      },
    };

    const {
      showMenu = false,
      showExtras = false,
      showUpsell = false,
      store_name = "",
      brand_name = "",
    } = this.state;
    const { webview = false, id: store_id } = this.props;

    return (
      <>
        <TitleHelmet title={this.state.title} />

        {this.state.showSplashScreen ? <LayoutSplashScreen /> : <></>}

        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} />
        ) : (
          <></>
        )}

        <>
          {store_name !== "" && brand_name !== "" ? (
            <HeaderTextWithActions
              breadcrumbs={
                <Breadcrumbs>
                  <Breadcrumbs.Item
                    text="Stores"
                    link={`${this.props.webview ? "/webview" : ""}/stores`}
                  />
                  <Breadcrumbs.Item
                    text={store_name}
                    // link={`/brands/${this.props.brand_url}/stores/${this.props.id}`}
                    link={`#`}
                  />
                  <Breadcrumbs.Item
                    text="Items and Categories"
                    active={"true"}
                    link={`#`}
                  />
                </Breadcrumbs>
              }
              title={store_name}
              subtitle={<></>}
            />
          ) : (
            <></>
          )}
        </>

        <div className="mv-1rem text-center">
          <div
            className="btn-group btn-group-lg"
            role="group"
            aria-label="Large button group"
          >
            <button
              type="button"
              className={`btn btn-${
                showMenu ? "primary" : "outline-primary"
              } font-weight-bolder`}
              data-tab="menu"
              onClick={this.onClickMainTab}
            >
              MENU
            </button>
            <button
              type="button"
              className={`btn btn-${
                showExtras ? "primary" : "outline-primary"
              } font-weight-bolder`}
              data-tab="extras"
              onClick={this.onClickMainTab}
            >
              EXTRAS
            </button>
            <button
              type="button"
              className={`btn btn-${
                showUpsell ? "primary" : "outline-primary"
              } font-weight-bolder`}
              data-tab="upsell"
              onClick={this.onClickMainTab}
            >
              UPSELL
            </button>
          </div>
        </div>

        {showMenu && (
          <ItemsComponent
            showHeaderText={false}
            page={page}
            store_id={store_id}
            setHeader={({ store_name = "", brand_name = "" }) => {
              this.setState({ store_name, brand_name });
            }}
            {...this.props}
            userId={this.props.user._id}
            role={this.props.user.iam_role}
          />
        )}

        {showExtras ? (
          <ExtrasComponent page={page} {...this.props} store_id={store_id} />
        ) : (
          <></>
        )}

        {showUpsell ? <UpsellComponent /> : <></>}
      </>
    );
  }
}

export class ItemsComponent extends APIComponent {
  constructor(props) {
    super(props);

    this.state = {
      showSplashScreen: false,
      redirectTo: "",
      title: "Per Store Items",

      showAddCategoryModal: false,
      showEditCategoryModal: false,
      showSelectionModal: false,

      deleteLoading: false,
      extraGroupErr: [],
    };

    this.api = core("pu").get();
    this.fileapi = core("puf").get();
    this.data_url = "/store/" + this.props.id + "/menu";

    this.getByItemId = this.getByItemId.bind(this);

    this.handleOnClickItem = this.handleOnClickItem.bind(this);

    this.onSubmitItem = this.onSubmitItem.bind(this);
    this.onSubmitEditItem = this.onSubmitEditItem.bind(this);
    this.onClickItemDelete = this.onClickItemDelete.bind(this);

    this.onSubmitCategory = this.onSubmitCategory.bind(this);
    this.onSubmitEditCategory = this.onSubmitEditCategory.bind(this);
    this.onClickCategoryDelete = this.onClickCategoryDelete.bind(this);

    this.updateList = this.updateList.bind(this);
    this.updateCategoriesOrder = this.updateCategoriesOrder.bind(this);
    this.uploadItemPhoto = this.uploadItemPhoto.bind(this);
  }
  async getData() {
    if (this.data_url !== "") {
      this._isMounted && this.setState({ data_status: "fetching" });
      this.api &&
        this.api
          .get(this.data_url)
          .then((data) => {
            this._isMounted &&
              this.setState({
                data: data.data,
                data_status: "fetched",
              });
            this.getExtras(data.data);
            this.handleOnDataFetched();

            if (this.use_display_data) {
              this.setDisplayData();
            }
          })
          .catch((error) => {
            this._isMounted &&
              this.setState({ error: error, showSwalError: true });
            this.handleOnDataFetchError();
          });
    }
  }
  getExtras = async (data) => {
    if (this.props.hasOwnProperty("store_id")) {
      const extras = await this.api.get(
        "/extras/get_group_by_store?store=" + this.props.store_id,
      );
      this.setState({ data: { ...data, extras: extras.data } });
    }
  };
  handleOnDataFetched = () => {
    const { name = "", brand = {} } = this.state.data;
    this.props.page &&
      this.props.page.setPageTitle(name + ": Items and Categories");
    this.props.setHeader &&
      this.props.setHeader({
        brand_name: brand.hasOwnProperty("name") ? brand.name : "",
        store_name: name,
      });
  };

  handleOnClickItem = (e) => {
    // const id = GetDataFromEvent(e, "data-id");
  };

  updateList = (items) => {
    this.setState({ data_status: "fetching" });
    items = items.filter(({ item_name = "" }) => {
      return item_name === "";
    });

    this.api
      .put({
        url: `/store/${this.props.id}/rearrange-items`,
        data: { items },
      })
      .then(({ data }) => {
        if (data.result === "ok") {
          this.setState({
            data_status: "fetched",
          });

          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: "You have successfully saved your changes.",
            showConfirmButton: false,
            timer: 1000,
          });
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  updateCategoriesOrder = (items) => {
    const categories = items.map((item) => {
      item.category_name = item.name;
      item.category_position = item.order_position;
      item.category_limit_per_time_slot = getDefaultTimeSlots(
        this.state.data,
        item.category_limit_per_time_slot_batch,
      );
      return item;
    });

    this.api
      .put({
        url: `/store/${this.props.id}/rearrange-categories`,
        data: { categories },
      })
      .then(({ data }) => {
        if (data.result === "ok") {
          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: "You have successfully saved your changes.",
            showConfirmButton: false,
            timer: 1000,
          });
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  getByItemId = (id) => {
    const { menu = [] } = this.state.data;
    const filtered = menu.filter((item) => {
      return item.item_id._id === id;
    });
    return filtered.length > 0 ? filtered[0] : {};
  };

  onSubmitItem = (values, setSubmitting, afterSubmit) => {
    const { _id = "", brand = "" } = this.state.data;

    values.brand = brand;
    values.store_id = _id;

    this.api
      .post({
        url: "/item",
        data: filterObj(values, ["_id"]),
      })
      .then(({ data }) => {
        setSubmitting(false);

        let { items = [] } = this.state;
        items.push(data);

        afterSubmit();

        this.swalNewItemToScroll(true, data.item_code);

        this.setState({
          showAddItemModal: false,
          extraGroupErr: [],
        });

        this.getData();
        this.props.refreshUserRedux();
      })
      .catch((error) => {
        setSubmitting(false);
        if (error.hasOwnProperty("response")) {
          const { data = {} } = error.response,
            { message = {} } = data;
          const err_message = message.split(" : ");
          if (
            err_message.length > 0 &&
            err_message[0] === "Extra Group does not exist"
          ) {
            const extra_group_errors = err_message[1].split(", ");
            MySwal.fire({
              icon: "error",
              title: "Ooops!",
              text: "Some extra groups don't exist in other stores. Please uncheck and try again",
              confirmButtonColor: PRIMARY_COLOR,
              confirmButtonText: "OK",
              showCancelButton: false,
            });
            this.setState({
              extraGroupErr: extra_group_errors,
            });
            setTimeout(() => {
              this.extraGroupRef.current.scrollIntoView({
                x: 0,
                y: 0,
                behaviour: "smooth",
              });
            }, 50);
          } else {
            this.handleError();
          }
        } else {
          this.handleError();
        }
      });
  };

  onSubmitEditItem = (values, setSubmitting, afterSubmit) => {
    const { _id = "" } = values;
    values.store_id = this.state.data._id;
    // console.log(values)
    this.api
      .put({
        url: "/item/" + _id,
        data: filterObj(values, ["_id"]),
      })
      .then(() => {
        setSubmitting(false);
        afterSubmit();

        MySwal.fire({
          icon: "success",
          title: "Success!",
          text: "You have successfully updated an item!",
          timer: 1000,
        });

        this.setState({
          showEditItemModal: false,
          extraGroupErr: [],
        });

        this.getData();
      })
      .catch((error) => {
        setSubmitting(false);
        if (error.hasOwnProperty("response")) {
          const { data = {} } = error.response,
            { message = {} } = data;
          const err_message = message.split(" : ");
          if (
            err_message.length > 0 &&
            err_message[0] === "Extra Group does not exist"
          ) {
            const extra_group_errors = err_message[1].split(", ");

            MySwal.fire({
              icon: "error",
              title: "Ooops!",
              text: "Some extra groups don't exist in other stores. Please uncheck and try again",
              confirmButtonColor: PRIMARY_COLOR,
              confirmButtonText: "OK",
              showCancelButton: false,
            });
            this.setState({
              extraGroupErr: extra_group_errors,
            });
          } else {
            this.handleError();
          }
        } else {
          this.handleError();
        }
      });
  };

  uploadItemPhoto = (args, setFilePath, setSubmitting) => {
    const formData = new FormData();
    for (const key in args) {
      formData.append(key, args[key]);
    }

    this.fileapi
      .post({
        url: "/util/upload-to-aws/items",
        data: formData,
      })
      .then(({ data }) => {
        const { filePath = "" } = data;
        setFilePath(filePath);
      })
      .catch((error) => {
        const { data = {} } = error.response,
          { message = {} } = data;
        if (message.error === "TokenExpiredError") {
          window.location.href = "/logout";
        } else if (message.error === "UnauthorizedUserError") {
          window.location.href = "/error/error-v1";
        } else {
          MySwal.fire({
            icon: "error",
            title: "Oops!",
            text: message ? message : "",
          });
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  onClickItemDelete = (apply_to_all = false) => {
    const { selectedItem = {}, data = {} } = this.state;

    MySwal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: `You are about to delete ${selectedItem.item_id.name}${
        apply_to_all ? " from all your stores" : ""
      }.`,
      confirmButtonColor: PRIMARY_COLOR,
      confirmButtonText: "Yes",
      showCancelButton: true,
      cancelButtonText: "No",
      reverseButtons: false,
    }).then((result) => {
      if (result.value) {
        this.setState({ deleteLoading: true });

        this.api
          .post({
            url: "/store/" + data._id + "/delete-menu-item",
            data: {
              _id: selectedItem.item_id._id,
              deleteDb: true,
              apply_to_all,
            },
          })
          .then(({ data }) => {
            if (data.result === "ok") {
              this.getData();
              this.setState({ deleteLoading: false, showEditItemModal: false });

              MySwal.fire({
                icon: "success",
                title: "Success!",
                text: `${selectedItem.item_id.name} successfully deleted.`,
                showCancelButton: false,
                timer: 1000,
              }).then(() => {
                this.setState({ selectedItem: {} });
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  // onSubmitExtra = (values, setSubmitting) => {
  //     let { _id = "" } = this.state.data.brand;
  //     values["brand_id"] = _id
  //     this.api.post({
  //         url: "/extras/create_group",
  //         data: values
  //     })
  //     .then(() => {
  //         setSubmitting(false);

  //         MySwal.fire({
  //             "icon" : "success",
  //             "title" : "Success!",
  //             "text" : "You have successfully added an Extra Group!",
  //             "timer" : 1000,
  //         });

  //         this.setState({ showAddExtraModal: false });

  //         this.getData();
  //     });
  // }
  onSubmitCategory = (values, setSubmitting) => {
    let { _id = "" } = this.state.data;

    this.api
      .post({
        url: "/store/" + _id + "/categories",
        data: values,
      })
      .then(() => {
        setSubmitting(false);

        this.swalNewItemToScroll(
          false,
          values.category_name.split(" ").join("_"),
        );

        this.setState({ showAddCategoryModal: false });

        this.getData();
      })
      .catch((error) => {
        setSubmitting(false);

        const { response = {} } = error,
          { data = {} } = response,
          { message = {} } = data;
        if (
          message.hasOwnProperty("error") &&
          message.error === "DuplicateCategoryError"
        ) {
          MySwal.fire({
            icon: "error",
            title: "Ooops!",
            text: "This is an existing category! Refrain from naming your new category with an existing one.",
          });
        } else {
          this.handleError();
        }
      });
  };

  onSubmitEditCategory = (values, setSubmitting) => {
    let { _id = "" } = this.state.data;

    this.api
      .put({
        url: "/store/" + _id + "/update-category-name",
        data: values,
      })
      .then(() => {
        setSubmitting(false);

        MySwal.fire({
          icon: "success",
          title: "Success!",
          text: "You have successfully updated a category!",
          timer: 1000,
        });

        this.setState({ showEditCategoryModal: false });

        this.getData();
      })
      .catch((error) => {
        setSubmitting(false);

        const { response = {} } = error,
          { data = {} } = response,
          { message = {} } = data;
        if (
          message.hasOwnProperty("error") &&
          message.error === "DuplicateCategoryError"
        ) {
          MySwal.fire({
            icon: "error",
            title: "Ooops!",
            text: "This is an existing category! Refrain from renaming your category with an existing one.",
          });
        } else {
          this.handleError();
        }
      });
  };

  onClickCategoryDelete = () => {
    const {
      selectedCategory = "",
      data: { menu = [], _id = "" },
    } = this.state;
    const items = getCategoryItems(menu, selectedCategory);

    if (items.length > 0) {
      MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: `You can only delete categories without items under it. Please move or delete the items before deleting this category.`,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    } else {
      MySwal.fire({
        icon: "warning",
        title: "Are you sure?",
        text: `You are about to delete ${selectedCategory}.`,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "Yes, I'm sure.",
        showCancelButton: true,
        cancelButtonText: "No",
        reverseButtons: false,
      }).then((result) => {
        if (result.value) {
          this.setState({ deleteLoading: true });

          this.api
            .post({
              url: "/store/" + _id + "/categories/delete",
              data: { category_name: selectedCategory },
            })
            .then(() => {
              this.setState({
                deleteLoading: false,
                showEditCategoryModal: false,
              });

              MySwal.fire({
                icon: "success",
                title: "Success!",
                text: "You have successfully deleted a category!",
                timer: 1000,
              });

              this.getData();
            });
        }
      });
    }
  };

  swalNewItemToScroll = (isItem = true, name = "") => {
    MySwal.fire({
      icon: "success",
      title: "Success!",
      text: `You have successfully added ${isItem ? "an" : "a"} ${
        isItem ? "item" : "category"
      }!`,
      confirmButtonColor: PRIMARY_COLOR,
      confirmButtonText: `Go to ${isItem ? "Item" : "Category"}`,
      showCloseButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        const prefix = isItem ? "item" : "group";
        const elem = document.getElementsByClassName(`${prefix}_${name}`)[0];
        if (elem) {
          elem.className += " highlight-item";
          elem.scrollIntoView({
            behaviour: "smooth",
            block: "center",
          });
          setTimeout(() => {
            elem.classList.remove("highlight-item");
          }, 1000);
        }
      }
      if (result.dismiss) {
        MySwal.close();
      }
    });
  };

  render() {
    const {
        data = {},
        data_status = "",
        showAddItemModal = false,
        showEditItemModal = false,
        extraGroupErr,
      } = this.state,
      isLoading = data_status !== "fetched";

    const { showHeaderText = true, origin = "normal" } = this.props;
    const userId = this.props.userId;
    const role = this.props.role;
    const actions = {
      handleOnClickItem: this.handleOnClickItem,
      updateList: this.updateList,
      updateCategoriesOrder: this.updateCategoriesOrder,
      onClickAddItem: () => {
        this.setState({ showAddItemModal: true, showSelectionModal: false });
      },
      onClickAddCategory: () => {
        this.setState({
          showAddCategoryModal: true,
          showSelectionModal: false,
        });
      },
      onClickItem: (e) => {
        const id = GetDataFromEvent(e, "data-id");
        this.setState({
          selectedItem: this.getByItemId(id),
          showEditItemModal: true,
        });
      },
      onClickCategory: (selectedCategory) => {
        const { categories = [] } = data;
        let category = categories.filter((c) => {
          return selectedCategory === c.category_name;
        });
        category = category.length > 0 ? category.shift() : {};

        const {
          category_limit_per_day = 0,
          category_limit_per_time_slot = [],
          visible = true,
          isExclusive = false,
        } = category;
        this.setState({
          selectedCategory,
          selectedCategoryLimit: category_limit_per_day,
          selectedCategoryLimitPerTimeSlot: category_limit_per_time_slot,
          showEditCategoryModal: true,
          visible,
          selectedCategoryIsExclusive: isExclusive,
        });
      },
      onClickAdd: () => {
        this.setState({ showSelectionModal: true });
      },
      onError: (error, maxFileSizeText = "200KB") => {
        if (error === "size-too-much") {
          MySwal.fire({
            icon: "error",
            title: "Ooops!",
            text: `File is too big! Maximum upload size is ${maxFileSizeText}.`,
            confirmButtonColor: PRIMARY_COLOR,
            confirmButtonText: "OK",
            showCancelButton: false,
          });
        } else if (error === "unsupported-file-extension") {
          MySwal.fire({
            icon: "error",
            title: "Ooops!",
            text: `File type unsupported. Please upload PNG, JPG and JPEG files only.`,
            confirmButtonColor: PRIMARY_COLOR,
            confirmButtonText: "OK",
            showCancelButton: false,
          });
        } else {
          MySwal.fire({
            icon: "error",
            title: "Ooops!",
            text: `Something went wrong`,
            confirmButtonColor: PRIMARY_COLOR,
            confirmButtonText: "OK",
            showCancelButton: false,
          });
        }
      },
    };
    this.extraGroupRef = React.createRef();

    return (
      <>
        {isLoading ? (
          <div className="h-100 w-100 d-flex flex-column align-items-center">
            <PacmanLoading />
          </div>
        ) : (
          <>
            {/* Add Item Modal */}
            <ItemModal
              method="add"
              title="Add Item"
              show={showAddItemModal}
              onHide={() => {
                this.setState({ showAddItemModal: false, extraGroupErr: [] });
              }}
              actions={actions}
              categories={data.categories.map((c) => c.category_name)}
              onSubmit={this.onSubmitItem}
              uploadItemPhoto={this.uploadItemPhoto}
              extras={data.extras}
              noAvailability={false}
              origin={origin}
              store={data}
              maxFileSize="25000"
              maxFileSizeText="25MB"
              userId={userId}
              role={role}
              extraGroupErr={extraGroupErr}
              extraGroupRef={this.extraGroupRef}
            />

            {/* Edit Item Modal */}
            <ItemModal
              method="edit"
              title="Edit Item"
              extras={data.extras}
              show={showEditItemModal}
              onHide={() => {
                this.setState({
                  selectedItem: {},
                  showEditItemModal: false,
                  extraGroupErr: [],
                });
              }}
              actions={actions}
              categories={data.categories.map((c) => c.category_name)}
              onSubmit={this.onSubmitEditItem}
              onClickDeleteBtn={this.onClickItemDelete}
              uploadItemPhoto={this.uploadItemPhoto}
              item={this.state.selectedItem}
              showDeleteButton={true}
              deleteLoading={this.state.deleteLoading}
              noAvailability={false}
              store={data}
              maxFileSize="25000"
              maxFileSizeText="25MB"
              userId={userId}
              role={role}
              extraGroupErr={extraGroupErr}
              extraGroupRef={this.extraGroupRef}
            />

            {/* Add Category Modal */}
            <CategoryModal
              show={this.state.showAddCategoryModal}
              onHide={() => {
                this.setState({ showAddCategoryModal: false });
              }}
              title="Add Category"
              onSubmit={this.onSubmitCategory}
              showDeleteButton={false}
              store={data}
            />

            {/* Edit Category Modal */}
            <CategoryModal
              show={this.state.showEditCategoryModal}
              onHide={() => {
                this.setState({ showEditCategoryModal: false });
              }}
              title="Edit Category"
              onSubmit={this.onSubmitEditCategory}
              onClickDeleteBtn={this.onClickCategoryDelete}
              category_name={this.state.selectedCategory}
              category_limit_per_day={this.state.selectedCategoryLimit}
              category_limit_per_time_slot={
                this.state.selectedCategoryLimitPerTimeSlot
              }
              showDeleteButton={true}
              deleteLoading={this.state.deleteLoading}
              store={data}
              visible={this.state.visible}
              isExclusive={this.state.selectedCategoryIsExclusive}
            />

            {/* Selection Modal */}

            <SelectionModal
              show={this.state.showSelectionModal}
              onHide={() => {
                this.setState({ showSelectionModal: false });
              }}
              title={<>What do you want to add?</>}
              // size={4}
              options={[
                {
                  title: "Category",
                  icon: "Text/Menu",
                  desc: "",
                  onClick: () => {
                    this.setState({
                      showAddCategoryModal: true,
                      showSelectionModal: false,
                    });
                  },
                },
                {
                  title: "Item",
                  icon: "Shopping/Cart2",
                  desc: "",
                  onClick: () => {
                    this.setState({
                      showAddItemModal: true,
                      showSelectionModal: false,
                    });
                  },
                },
                // {
                //     title: "Extras",
                //     icon: "Shopping/Bag2",
                //     desc: "",
                //     onClick: () => {
                //         this.setState({ showAddExtraModal: true, showSelectionModal: false })
                //     }
                // }
              ]}
            />

            {showHeaderText ? (
              <HeaderTextWithActions
                breadcrumbs={
                  <Breadcrumbs>
                    <Breadcrumbs.Item
                      text={this.state.data.brand.name}
                      link={`/brands/${this.props.brand_url}`}
                    />
                    <Breadcrumbs.Item
                      text="Stores"
                      link={`/brands/${this.props.brand_url}/stores`}
                    />
                    <Breadcrumbs.Item
                      text={data.name}
                      link={`/brands/${this.props.brand_url}/stores/${this.props.id}`}
                    />
                    <Breadcrumbs.Item
                      text="Items and Categories"
                      active={"true"}
                    />
                  </Breadcrumbs>
                }
                title={data.name}
                subtitle={<></>}
              />
            ) : (
              <></>
            )}

            <ItemList
              data={data}
              actions={actions}
              origin={origin}
              tagSettings={data.tag_settings}
            />
          </>
        )}
      </>
    );
  }
}

export class ExtrasComponent extends APIComponent {
  constructor(props) {
    super(props);
    this.state = {
      showAddExtraModal: false,
      showEditExtraModal: false,
      selectedExtraGroup: {},
    };

    this.api = core("pu").get();
    this.data_url = "/extras/get_group_by_store?store=" + this.props.store_id;
    this.onSubmitEditExtra = this.onSubmitEditExtra.bind(this);
    this.onSubmitExtra = this.onSubmitExtra.bind(this);
    this.onClickExtraDelete = this.onClickExtraDelete.bind(this);
  }
  onSubmitEditExtra = (values, setSubmitting, afterSubmit) => {
    // values["brand"] = this.props.user.brand._id;
    console.log(values);
    this.api
      .put({
        url: "/extras/update_group/" + this.state.selectedExtraGroup._id,
        data: values,
      })
      .then(() => {
        setSubmitting(false);

        MySwal.fire({
          icon: "success",
          title: "Success!",
          text: "You have successfully updated an Extra Group!",
          timer: 1000,
        });

        this.setState({ showEditExtraModal: false });

        this.getData();
      });
  };

  onClickExtraDelete = (extra_group_id) => {
    const store_id = this.props.store_id;
    MySwal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: `Your about to delete this extra group, this will also remove all attachments from the item`,
      confirmButtonColor: PRIMARY_COLOR,
      confirmButtonText: "Yes",
      showCancelButton: true,
      cancelButtonText: "No",
      reverseButtons: false,
    }).then((result) => {
      if (result.value) {
        this.setState({ deleteLoading: true });
        this.api
          .post({
            url: "/store/" + extra_group_id + "/delete-extra-group",
            data: {
              store_id,
              extra_group_id,
            },
          })
          .then(({ data }) => {
            if (data.result === "ok") {
              MySwal.fire({
                icon: "success",
                title: "Success!",
                text: "Extra group successfully deleted",
                timer: 1000,
              });
              this.setState({ showEditExtraModal: false });
              this.getData();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };
  onSubmitExtra = (values, setSubmitting) => {
    // let { _id = "" } = this.state.data.brand;

    values["store_id"] = this.props.store_id;
    this.api
      .post({
        url: "/extras/create_group",
        data: values,
      })
      .then(() => {
        setSubmitting(false);

        MySwal.fire({
          icon: "success",
          title: "Success!",
          text: "You have successfully added an Extra Group!",
          timer: 1000,
        });

        this.setState({ showAddExtraModal: false });

        this.getData();
      });
  };

  render() {
    const openEditModal = (extra) => {
      console.log(extra);
      this.setState({
        showEditExtraModal: true,
        selectedExtraGroup: extra,
      });
    };

    const { data, showEditExtraModal, data_status } = this.state,
      isLoading = data_status !== "fetched";
    return (
      <>
        {isLoading ? (
          <div className="h-100 w-100 d-flex flex-column align-items-center">
            <PacmanLoading />
          </div>
        ) : (
          <>
            {/* Create Extra Modal */}
            <ExtraModal
              title="Create Extras Group"
              show={this.state.showAddExtraModal}
              onHide={() => {
                this.setState({ showAddExtraModal: false });
              }}
              // actions={actions}
              onSubmit={this.onSubmitExtra}
            />
            <Card className="card-custom">
              <div className="card-header">
                <div className="card-title">
                  <h3 className="card-label">Extras Group </h3>
                </div>
                <div className="card-toolbar">
                  <Button
                    variant="primary"
                    onClick={() => {
                      this.setState({ showAddExtraModal: true });
                    }}
                  >
                    Add
                  </Button>
                </div>
              </div>
              <Card.Body className={"card-body-min-350"}>
                <ListGroup>
                  {data ? (
                    data.map((extra, i) => (
                      <ListGroup.Item key={i}>
                        <Row
                          onClick={() => {
                            openEditModal(extra);
                          }}
                        >
                          <Col lg={0.5}>
                            <span class="align-items-center">
                              <EditIcon />
                            </span>
                            {/* <SVGIcon icon={"Text/Edit"} className={`svg-icon-2x svg-icon-success`}/> */}
                          </Col>
                          <Col lg={3}>
                            <label className={"py-4"}>{extra.name}</label>
                          </Col>
                          <Col lg={6}>
                            {extra.group_selections.map((selection, index) => (
                              <label className={"py-4 px-1"}>
                                {" "}
                                {selection.extra_id?.name}
                                {index === extra.group_selections.length - 1
                                  ? ""
                                  : ","}
                              </label>
                            ))}
                          </Col>
                          <Col lg={1.5}>
                            <label className={"py-4"}>
                              {" "}
                              <span class="label label-warning label-inline font-weight-bold mr-2">
                                {" "}
                                {extra.maximum_number} MAXIMUM
                              </span>
                            </label>
                          </Col>
                          <Col lg={1.5}>
                            <label className={"py-4"}>
                              {extra.minimum_number === 0 ? (
                                <span class="label label-primary label-inline font-weight-bold mr-2">
                                  OPTIONAL
                                </span>
                              ) : (
                                <span class="label label-info label-inline font-weight-bold mr-2">
                                  {extra.minimum_number} REQUIRED
                                </span>
                              )}
                            </label>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    ))
                  ) : (
                    <></>
                  )}
                </ListGroup>
              </Card.Body>
            </Card>
            <ExtraModal
              title="Edit Extras Group"
              show={showEditExtraModal}
              showDeleteButton={true}
              onClickExtraDelete={this.onClickExtraDelete}
              onHide={() => {
                this.setState({ showEditExtraModal: false });
              }}
              item={this.state.selectedExtraGroup}
              onSubmit={this.onSubmitEditExtra}
            />
          </>
        )}
      </>
    );
  }
}

export class UpsellComponent extends Component {
  render() {
    return <ComingSoonDiv />;
  }
}

function sort(arr, field) {
  return arr.sort((a, b) => {
    if (a[field] > b[field]) return 1;
    if (a[field] < b[field]) return -1;
    else return 0;
  });
}

function getCategoryItems(menu = [], category_name = "") {
  return sort(
    menu.filter((item) => item.category === category_name),
    "order_position",
  );
}

function groupMenu2({ categories = [], menu = [] }) {
  let data = sort(categories, "category_position");

  for (var i = 0; i < data.length; i++) {
    const { category_name = "" } = data[i];
    data[i]._id = category_name;
    const category_items = getCategoryItems(menu, category_name);
    data[i].items =
      category_items.length > 0
        ? category_items
        : [
            {
              _id: `${category_name}_1`,
              item_name: "ITEM_GROUP_PLACEHOLDER",
            },
          ];
  }

  return data;
}

class ItemList extends Component {
  render() {
    const items = groupMenu2(this.props.data);

    const { origin = "normal", tagSettings } = this.props;

    return (
      <Card className="card-custom">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">Items and Categories</h3>
          </div>
          <div className="card-toolbar">
            <Button variant="primary" onClick={this.props.actions.onClickAdd}>
              Add
            </Button>
          </div>
        </div>
        <Card.Body className={"card-body-min-350"}>
          {origin === "normal" ? (
            <div className="mv-1rem d-flex flex-row justify-content-space-between">
              {" "}
              <Button variant="outline-primary">
                <span>
                  <i className="flaticon2-download"></i>
                </span>{" "}
                Import Menu Template
              </Button>{" "}
              <Button variant="outline-primary mr-2">
                Save As Template{" "}
                <span>
                  <i className="flaticon-logout"></i>
                </span>
              </Button>
            </div>
          ) : (
            <></>
          )}

          {items.length > 0 ? (
            <ItemListDnD
              actions={this.props.actions}
              data={items}
              tagSettings={tagSettings}
            />
          ) : (
            <>
              {origin === "normal" ? (
                <div>
                  No items found.{" "}
                  <KTLink
                    text={<>Do you want to add one?</>}
                    onClick={this.props.actions.onClickAddItem}
                  />
                </div>
              ) : (
                <></>
              )}

              {origin === "getting_started" ? (
                <div>
                  Start adding items to your store by clicking{" "}
                  <KTLink
                    text={<>here</>}
                    onClick={this.props.actions.onClickAddItem}
                  />
                  .
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </Card.Body>
      </Card>
    );
  }
}

class ExtraModal extends Component {
  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        size="xl"
        centered
      >
        <Modal.Header>
          <Modal.Title>{this.props.title}</Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={this.props.onHide}
          >
            <i aria-hidden="true" className="ki ki-close"></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          <ExtrasForm {...this.props} />
        </Modal.Body>
      </Modal>
    );
  }
}

class ItemModal extends Component {
  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        size="xl"
        centered
      >
        <Modal.Header>
          <Modal.Title>{this.props.title}</Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={this.props.onHide}
          >
            <i aria-hidden="true" className="ki ki-close"></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          <ItemForm
            {...this.props}
            userId={this.props.userId}
            role={this.props.role}
          />
        </Modal.Body>
      </Modal>
    );
  }
}

class CategoryModal extends Component {
  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide} centered>
        <Modal.Header>
          <Modal.Title>{this.props.title}</Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={this.props.onHide}
          >
            <i aria-hidden="true" className="ki ki-close"></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          <CategoryForm {...this.props} />
        </Modal.Body>
      </Modal>
    );
  }
}
