import React, { Suspense, useEffect } from "react";
import { Redirect, Switch } from "react-router-dom";
import { useHistory } from "react-router";

import { useSelector, useDispatch } from "react-redux";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";

import GettingStarted from "./pages/client-admin/GettingStarted";
import MyAccountPage from "./pages/client-admin/MyAccount";
import ContactUsPage from "./pages/client-admin/ContactUs";
import DashboardPage from "./pages/client-admin/DashboardPage";
import OrgAnalytics from "./pages/client-admin/OrgAnalytics";
import OrgGoogleAnalytics from "./pages/client-admin/OrgGoogleAnalytics";
import Onboarding from "./pages/client-admin/Onboarding";

import HelpPage from "./pages/client-admin/HelpPageNew";
import HelpPageTopic from "./pages/client-admin/HelpPageTopic";

import BrandsPage from "./pages/client-admin/Brands";
import StoresPage from "./pages/client-admin/Stores";
import OrdersPage from "./pages/client-admin/Orders";
import GroupsPage from "./pages/client-admin/Groups";
import AnnouncementsPage from "./pages/client-admin/Announcements";
import AdminToolsPage from "./pages/pickup-admin/AdminToolsPage";
import AdminToolsBrands from "./pages/pickup-admin/AdminToolsBrandSelect";
import AdminToolsStores from "./pages/pickup-admin/AdminToolsStoreSelect";
import AdminToolsUsers from "./pages/pickup-admin/AdminToolsUserSelect";
import AdminToolsOrders from "./pages/pickup-admin/AdminToolsOrderSelect";
import SuperBrandsPage from "./pages/client-admin/SuperBrandList";

import MenuPage from "./pages/client-admin/Menu";
import PaymongoPage from "./pages/client-admin/Paymongo";
import CancelledOrdersPage from "./pages/client-admin/CancelledOrders";
// import CustomizeWebsitePage from "./pages/client-admin/CustomizeWebsite";

import MultiBrandPage from "./pages/client-admin/MultiBrandDashboard";
import MultiBrandAnalyticsPage from "./pages/client-admin/MultiBrandAnalytics";
import MultiBrandGoogleAnalyticsPage from "./pages/client-admin/MultiBrandGoogleAnalytics";
import StoreAnalytics from "./pages/client-admin/StoreAnalytics";
import PerBrandPage from "./pages/client-admin/PerBrand";
import PerBrandAdminPage from "./pages/client-admin/PerBrandAdmin";
import PerStorePage from "./pages/client-admin/PerStore";
import PerStoreAdminPage from "./pages/client-admin/PerStoreAdmin";
import PerOrderPage from "./pages/client-admin/PerOrder";
import PerGroupPage from "./pages/client-admin/PerGroup";

import ReportsStorePage from "./pages/client-admin/reports/Store";
import ReportsGroupPage from "./pages/client-admin/reports/Group";
import ReportsSuperPage from "./pages/client-admin/reports/SuperAdmin";
import BrandSalesPage from "./pages/client-admin/reports/BrandSales";

import PerGroupBrandsPage from "./pages/client-admin/PerGroupBrands";
import PerGroupStoresPage from "./pages/client-admin/PerGroupStores";
import PerGroupUsersPage from "./pages/client-admin/PerGroupUsers";
import PerGroupSettingsPage from "./pages/client-admin/PerGroupSettings";

import PerBrandSettingsPage from "./pages/client-admin/PerBrandSettings";
import PerStoreSettingsPage from "./pages/client-admin/PerStoreSettings";
import PerStoreItemsPage from "./pages/client-admin/PerStoreItems";
import PerStoreOrdersPage from "./pages/client-admin/PerStoreOrders";
import PerStoreCancelledOrdersPage from "./pages/client-admin/PerStoreCancelledOrders";
import PerStoreReservationsPage from "./pages/client-admin/PerStoreReservations";
import PerStoreEventsPage from "./pages/client-admin/PerStoreEvents";
import ReservationsDashboard from "./pages/client-admin/reservations-console/ReservationsDashboard";
import ReservationsConsole from "./pages/client-admin/reservations-console/ReservationsConsole";

// import ManageBrandPage from "./pages/client-admin/ManageBrand";
import SettingsPage from "./pages/client-admin/Settings";
import AnalyticsPage from "./pages/client-admin/Analytics";

// import PerItemPage from "./pages/client-admin/PerItem";
import Reports from "./pages/client-admin/Reports";

import { generatePickupPhLink, getFirstItem } from "./helpers";
// import { messaging ,isMessagingSupported } from "./modules/Firebase/init-fcm.js"
// import { FirebaseHelpers} from "./modules/Firebase/FirebaseHelpers"
import { Button, Modal } from "react-bootstrap";
import {
  // socket,
  // setConnection,
  storeListenerConnected,
} from "./modules/SocketIO/socketInit";
import UnauthorizedPage from "./components/UnauthorizedPage";
import { PAGES_PRIVILEGES } from "./constants";
import NewBrand from "./partials/forms/NewBrand";
import NewStore from "./partials/forms/NewStore";
// import EditBrand from "./partials/forms/EditBrand";
import MenuSelectionPage from "./pages/client-admin/MenuSelectionPage";
import OrderSelectionPage from "./pages/client-admin/OrderSelectionPage";
import ReservationSelectionPage from "./pages/client-admin/ReservationSelectionPage";
import EventSelectionPage from "./pages/client-admin/EventSelectionPage";

import PromosPage from "./pages/client-admin/Promos";

import PlaceOrderSelectionPage from "./pages/client-admin/PlaceOrderSelectionPage";
import { actionTypes } from "./modules/Auth/_redux/authRedux";
// import GoogleAnalyticsPage from "./pages/client-admin/GoogleAnalytics";

function LayoutRoute(props) {
  const {
    Layout,
    LayoutWithSideNav,
    sideNav = false,
    component,
    path,
    finishedSetup,
    webview = false,
    privileges = [],
    scope = "",
    user = "",
  } = props;

  const propsToInherit = {
    component,
    path,
    finishedSetup,
    privileges,
    webview,
    user,
  };

  return scope !== "" && privileges.includes(scope) ? (
    sideNav ? (
      <LayoutWithSideNav>
        <ContentRoute {...propsToInherit} />
      </LayoutWithSideNav>
    ) : (
      <Layout contentOnly={webview} privileges={privileges} activeMenu={scope}>
        <ContentRoute {...propsToInherit} />
      </Layout>
    )
  ) : (
    <UnauthorizedPage />
  );
}

function getMainPage(privileges = []) {
  let mainPage = "";
  for (var i = 0; i < PAGES_PRIVILEGES.length; i++) {
    if (privileges.includes(PAGES_PRIVILEGES[i])) {
      mainPage = PAGES_PRIVILEGES[i];
      break;
    }
  }

  return mainPage;
}

function createUrl(pickuphlink, mainPage) {
  let link = "/brands/" + pickuphlink;

  if (["stores", "menu", "orders", "analytics"].indexOf(mainPage) !== -1) {
    link = link + "/" + mainPage;
  } else if (mainPage === "home") {
    link = "/dashboard";
  } else {
    link = "/" + mainPage;
  }

  return link;
}

function getFirstBrandURL(brand_url = []) {
  return brand_url.length > 0 ? brand_url[0] : "";
}

export default function BasePage(props) {
  const { user } = useSelector((state) => state.auth),
    { finished_setup = false, iam_role = "" } = user,
    isSuperAdmin = Boolean(
      iam_role === "super_admin" || iam_role === "admin_console",
    ),
    isSystemAdmin = Boolean(iam_role === "system_admin");

  const [open, setOpen] = React.useState(false);
  const [modalTitle, setModalTitle] = React.useState("");
  const [messageData, setMessageData] = React.useState({});
  const [infoType, setInfoType] = React.useState("");
  const [newOrderCounter, setNewOrderCounter] = React.useState(0);
  const finishedSetup =
    isSuperAdmin || isSystemAdmin
      ? true
      : (user.brands.length > 0 && user.stores.length > 0) || finished_setup;
  const { privileges = [] } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: actionTypes.UserLoaded, payload: { user } });
  }, []);

  useEffect(() => {
    if (!finishedSetup) history.push("/getting-started");
  }, [finishedSetup, history]);

  let homepageUrl = isSuperAdmin
    ? "/admin/dashboard"
    : isSystemAdmin
    ? "/admin/tools"
    : finishedSetup
    ? createUrl(
        generatePickupPhLink(getFirstItem(user.brand_urls), false),
        getMainPage(privileges),
      )
    : "/getting-started";

  const propsToInherit = { finishedSetup, user };

  const eventHandler = (message) => {
    const { data = {} } = message;

    setMessageData(data);

    if (data.hasOwnProperty("url")) {
      setInfoType(data.url);
      if (data.url === "PerOrder") {
        setModalTitle("You have received a new order!");
        setNewOrderCounter(newOrderCounter + 1);
      } else if (data.url === "Orders") {
        setModalTitle("You have orders coming up!");
      }
    }

    if (
      props.hasOwnProperty("location") &&
      props.location.hasOwnProperty("pathname")
    ) {
      const { pathname = "" } = props.location;
      if (pathname.indexOf("/orders") !== -1) {
        // only show order modal on orders page
        setOpen(true);
      }
    }
  };

  if (storeListenerConnected === false) {
    if (
      user.hasOwnProperty("store") &&
      user.store !== null &&
      user.store.hasOwnProperty("_id")
    ) {
      // socket.on( user.store._id,eventHandler)
      // setConnection(true)
    }
  }

  return (
    <>
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          {
            /* Redirect from root URL to /dashboard. */
            <Redirect exact from="/" to={homepageUrl} />
          }

          <LayoutRoute
            path="/brands/:brand_url/stores/:store_id/cancelled-orders"
            scope="orders"
            component={PerStoreCancelledOrdersPage}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/brands/:brand_url/stores/:store_id/menu"
            scope="menu"
            component={PerStoreItemsPage}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/brands/:brand_url/stores/:store_id/orders"
            scope="orders"
            component={PerStoreOrdersPage}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/brands/:brand_url/stores/:store_id/settings"
            scope="stores"
            component={PerStoreSettingsPage}
            {...props}
            {...propsToInherit}
          />

          {/* <LayoutRoute path="/brands/:brand_url/items/:id" component={PerItemPage} {...props} {...propsToInherit}/> */}
          <LayoutRoute
            path="/brands/:brand_url/stores/:store_id/analytics"
            scope="analytics"
            component={StoreAnalytics}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/brands/:brand_url/stores/:store_id/googleanalytics"
            scope="google-analytics"
            component={MultiBrandGoogleAnalyticsPage}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/brands/:brand_url/stores/:store_id/reports"
            scope="home"
            component={ReportsStorePage}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/brands/:brand_url/stores/:store_id"
            scope="home"
            component={PerStorePage}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/orders/:order_id"
            scope="orders"
            component={PerOrderPage}
            {...props}
            {...propsToInherit}
          />

          {/* <LayoutRoute path="/brands/:id/items" component={ItemsPage} {...props} {...propsToInherit}/> */}
          <LayoutRoute
            path="/brands/:brand_url/cancelled-orders"
            scope="orders"
            component={CancelledOrdersPage}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/brands/:brand_url/orders"
            scope="orders"
            component={OrdersPage}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/brands/:brand_url/placeorders"
            scope="place-orders"
            component={PlaceOrderSelectionPage}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/brands/:brand_url/stores"
            scope="stores"
            component={StoresPage}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/brands/:brand_url/menu"
            scope="menu"
            component={MenuPage}
            {...props}
            {...propsToInherit}
          />
          {/* <LayoutRoute path="/brands/:id/customize" component={CustomizeWebsitePage} {...props} {...propsToInherit}/> */}
          {/* <LayoutRoute path="/brands/:brand_url/manage" scope="stores" component={ManageBrandPage} {...props} {...propsToInherit}/> */}
          <LayoutRoute
            path="/brands/:brand_url/settings"
            scope="stores"
            component={PerBrandSettingsPage}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/brands/:brand_url/analytics"
            scope="analytics"
            component={AnalyticsPage}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/brands/:brand_url/googleanalytics"
            exact
            scope="google-analytics"
            component={MultiBrandGoogleAnalyticsPage}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/brands/:brand_url/reports"
            scope="home"
            component={Reports}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/brands/:brand_url"
            scope="home"
            component={PerBrandPage}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/brands"
            scope="home"
            component={BrandsPage}
            {...props}
            {...propsToInherit}
          />

          <LayoutRoute
            path="/groups/:group_url/settings"
            scope="stores"
            component={PerGroupSettingsPage}
            {...props}
            {...propsToInherit}
          />

          <LayoutRoute
            path="/admin/groups/:group_id/brands/:brand_id/stores/:store_id"
            scope="groups"
            component={PerStoreAdminPage}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/admin/groups/:group_id/stores"
            scope="groups"
            component={PerGroupStoresPage}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/admin/groups/:group_id/users"
            scope="groups"
            component={PerGroupUsersPage}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/admin/groups/:group_id/brands/:brand_id"
            scope="groups"
            component={PerBrandAdminPage}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/admin/groups/:group_id/brands"
            scope="groups"
            component={PerGroupBrandsPage}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/admin/groups/:group_id"
            scope="groups"
            component={PerGroupPage}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/admin/groups"
            scope="groups"
            component={GroupsPage}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/admin/brands"
            scope="brands"
            component={SuperBrandsPage}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/admin/dashboard"
            scope="home"
            component={DashboardPage}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/admin/analytics"
            scope="analytics"
            component={OrgAnalytics}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/admin/googleanalytics"
            scope="google-analytics"
            component={OrgGoogleAnalytics}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/admin/onboarding"
            scope="onboarding"
            component={Onboarding}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/admin/reports/brands"
            scope="home"
            component={BrandSalesPage}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/admin/reports"
            scope="home"
            component={ReportsSuperPage}
            {...props}
            {...propsToInherit}
          />

          <LayoutRoute
            path="/admin/announcements"
            scope="announcements"
            component={AnnouncementsPage}
            {...props}
            {...propsToInherit}
          />

          <LayoutRoute
            path="/announcements"
            scope="announcements"
            component={AnnouncementsPage}
            {...props}
            {...propsToInherit}
          />

          <LayoutRoute
            path="/admin/tools/brands/:tool_name"
            scope="admintools"
            component={AdminToolsBrands}
            {...props}
            {...propsToInherit}
          />

          <LayoutRoute
            path="/admin/tools/brands"
            scope="admintools"
            component={AdminToolsBrands}
            {...props}
            {...propsToInherit}
          />

          <LayoutRoute
            path="/admin/tools/stores/:tool_name"
            scope="admintools"
            component={AdminToolsStores}
            {...props}
            {...propsToInherit}
          />

          <LayoutRoute
            path="/admin/tools/stores"
            scope="admintools"
            component={AdminToolsStores}
            {...props}
            {...propsToInherit}
          />

          <LayoutRoute
            path="/admin/tools/users/:tool_name"
            scope="admintools"
            component={AdminToolsUsers}
            {...props}
            {...propsToInherit}
          />

          <LayoutRoute
            path="/admin/tools/users"
            scope="admintools"
            component={AdminToolsUsers}
            {...props}
            {...propsToInherit}
          />

          <LayoutRoute
            path="/admin/tools/orders/:tool_name"
            scope="admintools"
            component={AdminToolsOrders}
            {...props}
            {...propsToInherit}
          />

          <LayoutRoute
            path="/admin/tools/orders"
            scope="admintools"
            component={AdminToolsOrders}
            {...props}
            {...propsToInherit}
          />

          <LayoutRoute
            path="/admin/tools"
            scope="admintools"
            component={AdminToolsPage}
            {...props}
            {...propsToInherit}
          />

          <LayoutRoute
            path="/getting-started"
            scope="my-account"
            component={GettingStarted}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/my-account"
            scope="my-account"
            component={MyAccountPage}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/feedback"
            scope="feedback"
            component={ContactUsPage}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/settings"
            scope="settings"
            component={SettingsPage}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/paymongo"
            scope="settings"
            component={PaymongoPage}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/promos"
            scope="promos"
            component={PromosPage}
            {...props}
            {...propsToInherit}
          />

          {/* Help Page */}
          <LayoutRoute
            path="/help/common-mistakes"
            scope="help"
            component={() => (
              <HelpPageTopic
                title={"Avoid Common Mistakes"}
                data_url={"/help/common-mistakes"}
              />
            )}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/help/device-setup"
            scope="help"
            component={() => (
              <HelpPageTopic
                title={"Sunmi Device Setup"}
                data_url={"/help/device-setup"}
              />
            )}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/help/store-management"
            scope="help"
            component={() => (
              <HelpPageTopic
                title={"Sunmi Store Management"}
                data_url={"/help/store-management"}
              />
            )}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/help/restaurant-portal"
            scope="help"
            component={() => (
              <HelpPageTopic
                title={"Restaurant Portal"}
                data_url={"/help/restaurant-portal"}
              />
            )}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/help/delivery-guide"
            scope="help"
            component={() => (
              <HelpPageTopic
                title={"Delivery Guide"}
                data_url={"/help/delivery-guide"}
              />
            )}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/help/meal-plan"
            scope="help"
            component={() => (
              <HelpPageTopic
                title={"Meal Plan Feature"}
                data_url={"/help/meal-plan"}
              />
            )}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/help/sales-reports"
            scope="help"
            component={() => (
              <HelpPageTopic
                title={"Utilizing Reports"}
                data_url={"/help/sales-reports"}
              />
            )}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/help/menu"
            scope="help"
            component={() => (
              <HelpPageTopic title={"Menu Uploading"} data_url={"/help/menu"} />
            )}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/help/order-dispatch"
            scope="help"
            component={() => (
              <HelpPageTopic
                title={"Order Dispatch & Food Packaging"}
                data_url={"/help/order-dispatch"}
              />
            )}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/help/payments"
            scope="help"
            component={() => (
              <HelpPageTopic
                title={"Payment Management"}
                data_url={"/help/payments"}
              />
            )}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/help/instagram"
            scope="help"
            component={() => (
              <HelpPageTopic
                title={"Instagram Bio How To"}
                data_url={"/help/instagram"}
              />
            )}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/help/faq"
            scope="help"
            component={() => (
              <HelpPageTopic
                title={"Frequently Asked Questions"}
                data_url={"/help/faq"}
              />
            )}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/help/promo-code"
            scope="help"
            component={() => (
              <HelpPageTopic
                title={"Promo Code Guide"}
                data_url={"/help/promo-code"}
              />
            )}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/help"
            scope="help"
            component={HelpPage}
            {...props}
            {...propsToInherit}
          />

          {/* Multi */}
          <LayoutRoute
            path="/dashboard"
            exact
            scope="home"
            component={MultiBrandPage}
            sideNav={false}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/analytics"
            exact
            scope="analytics"
            component={MultiBrandAnalyticsPage}
            sideNav={false}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/googleanalytics"
            exact
            scope="google-analytics"
            component={MultiBrandGoogleAnalyticsPage}
            sideNav={false}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/reports"
            exact
            scope="home"
            component={ReportsGroupPage}
            sideNav={false}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/stores"
            exact
            scope="stores"
            component={StoresPage}
            sideNav={false}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/menus"
            exact
            scope="menu"
            component={MenuSelectionPage}
            sideNav={false}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/orders"
            exact
            scope="orders"
            component={OrderSelectionPage}
            sideNav={false}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/reservations"
            exact
            scope="reservations"
            component={ReservationSelectionPage}
            sideNav={false}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/reservations/new"
            exact
            scope="reservations"
            component={ReservationsDashboard}
            sideNav={false}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/brands/:brand_url/stores/:store_id/reservations/new"
            scope="reservations"
            component={ReservationsConsole}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/stores/:store_id/reservations/new"
            exact
            scope="reservations"
            component={ReservationsConsole}
            sideNav={false}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/brands/:brand_url/stores/:store_id/reservations"
            scope="reservations"
            component={PerStoreReservationsPage}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/stores/:id/reservations"
            exact
            scope="reservations"
            component={PerStoreReservationsPage}
            sideNav={false}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/events"
            exact
            scope="events"
            component={EventSelectionPage}
            sideNav={false}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/brands/:brand_url/stores/:store_id/events"
            scope="events"
            component={PerStoreEventsPage}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/stores/:id/events"
            exact
            scope="events"
            component={PerStoreEventsPage}
            sideNav={false}
            {...props}
            {...propsToInherit}
          />

          <LayoutRoute
            path="/placeorders"
            exact
            scope="place-orders"
            component={() => PlaceOrderSelectionPage(propsToInherit)}
            sideNav={false}
            {...props}
            {...propsToInherit}
            {...user}
          />
          <LayoutRoute
            path="/stores/:id/settings"
            scope="stores"
            component={PerStoreSettingsPage}
            sideNav={false}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/stores/:id/menu"
            exact
            scope="menu"
            component={PerStoreItemsPage}
            sideNav={false}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/stores/:id/orders"
            exact
            scope="orders"
            component={PerStoreOrdersPage}
            sideNav={false}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/stores/new-brand"
            exact
            scope="stores"
            component={NewBrand}
            sideNav={false}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/stores/new-store/:brand?"
            scope="stores"
            component={NewStore}
            sideNav={false}
            {...props}
            {...propsToInherit}
          />
          {/* <LayoutRoute path="/brand/:id/edit" exact scope="stores" component={EditBrand} sideNav={false} {...props} {...propsToInherit}/> */}

          {/* START:: Webview */}

          <LayoutRoute
            path="/:mode/analytics"
            exact
            scope="analytics"
            component={MultiBrandAnalyticsPage}
            webview={true}
            sideNav={false}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/:mode/googleanalytics"
            exact
            scope="google-analytics"
            component={MultiBrandGoogleAnalyticsPage}
            webview={true}
            sideNav={false}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/:mode/brands/:brand_url/analytics"
            scope="analytics"
            component={AnalyticsPage}
            webview={true}
            sideNav={false}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/:mode/brands/:brand_url/settings"
            scope="stores"
            component={PerBrandSettingsPage}
            webview={true}
            {...props}
            {...propsToInherit}
          />
          {/* <LayoutRoute path="/:mode/brand/:id/edit" exact scope="stores" component={EditBrand} webview={true}  sideNav={false} {...props} {...propsToInherit}/> */}
          <LayoutRoute
            path="/:mode/brands/:brand_url/stores/:store_id/analytics"
            scope="analytics"
            component={StoreAnalytics}
            sideNav={false}
            webview={true}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/:mode/brands/:brand_url/googleanalytics"
            exact
            scope="google-analytics"
            component={MultiBrandGoogleAnalyticsPage}
            webview={true}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/:mode/brands/:brand_url/stores/:store_id/menu"
            scope="menu"
            component={PerStoreItemsPage}
            sideNav={false}
            webview={true}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/:mode/brands/:brand_url/stores/:store_id/settings"
            scope="stores"
            component={PerStoreSettingsPage}
            sideNav={false}
            webview={true}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/:mode/brands/:brand_url/reports"
            scope="home"
            component={Reports}
            webview={true}
            sideNav={false}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/:mode/brands/:brand_url/orders/:order_id"
            scope="orders"
            component={PerOrderPage}
            webview={true}
            sideNav={false}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/:mode/brands/:brand_url/stores/:store_id/googleanalytics"
            scope="google-analytics"
            component={MultiBrandGoogleAnalyticsPage}
            webview={true}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/:mode/brands/:brand_url/stores/:store_id/reports"
            scope="home"
            component={ReportsStorePage}
            webview={true}
            sideNav={false}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/:mode/brands/:brand_url/stores/:store_id"
            scope="home"
            component={PerStorePage}
            webview={true}
            sideNav={false}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/:mode/brands/:brand_url"
            scope="home"
            component={PerBrandPage}
            webview={true}
            sideNav={false}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/:mode/dashboard"
            exact
            scope="home"
            component={MultiBrandPage}
            webview={true}
            sideNav={false}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/:mode/feedback"
            scope="feedback"
            component={ContactUsPage}
            sideNav={false}
            webview={true}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/:mode/menus"
            exact
            scope="menu"
            component={MenuSelectionPage}
            webview={true}
            sideNav={false}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/:mode/stores"
            exact
            scope="stores"
            component={StoresPage}
            webview={true}
            sideNav={false}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/:mode/settings"
            scope="settings"
            component={SettingsPage}
            webview={true}
            sideNav={false}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/:mode/stores/new-brand"
            exact
            scope="stores"
            component={NewBrand}
            webview={true}
            sideNav={false}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/:mode/stores/new-store/:brand?"
            scope="stores"
            component={NewStore}
            webview={true}
            sideNav={false}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/:mode/stores/:id/menu"
            exact
            scope="menu"
            component={PerStoreItemsPage}
            webview={true}
            sideNav={false}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/:mode/stores/:id/settings"
            scope="stores"
            component={PerStoreSettingsPage}
            webview={true}
            sideNav={false}
            {...props}
            {...propsToInherit}
          />
          <LayoutRoute
            path="/:mode/reports"
            exact
            scope="home"
            component={ReportsGroupPage}
            webview={true}
            sideNav={false}
            {...props}
            {...propsToInherit}
          />
          {/* END:: Webview */}

          <Redirect to="error/error-v1" />
        </Switch>
      </Suspense>

      <Modal
        centered
        show={open}
        onHide={() => {
          setOpen(false);
        }}
      >
        <Modal.Header>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            {infoType === "PerOrder" ? (
              <>
                <div className="display-1">{newOrderCounter}</div>
                <div style={{ fontSize: "2rem" }}>
                  new order{newOrderCounter > 1 ? <>s</> : <></>}
                </div>
              </>
            ) : (
              <></>
            )}

            {infoType === "Orders" ? (
              <h1>You have orders coming up!</h1>
            ) : (
              <></>
            )}
          </div>
          <Button
            variant="primary"
            className="btn-block text-uppercase mt-2"
            onClick={() => {
              if (user) {
                const { brand = {} } = user;

                if (infoType === "PerOrder" && newOrderCounter === 1) {
                  window.location = `/brands/${brand.brand_url}/orders/${messageData.order_id}`;
                } else {
                  window.location = `/brands/${brand.brand_url}/orders`;
                }
              }
            }}
          >
            go to order{newOrderCounter > 1 ? <>s</> : <></>}
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
}
